import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTrack } from '../chart/chart.types';
import { Clip3DData, MediaData } from '../multi-media-player/media-player/media-player.types';
import { TileUpdateIdsAndType } from './left-right';

export type Tile = DataTrack | MediaData | Clip3DData | ImageData;
const MAX_ROWS = 2; // The max amount of tiles per column

export interface ImageData {
  id?: string;
  originalId?: string;
  dataUrl?: string;
  imageSrc: string;
}

export interface ReportLayoutConfig {
  layoutName: string;
  selectedTrialName: string,
  leftTileIds: ReportLayoutTileId[],
  rightTileIds: ReportLayoutTileId[],
  version?: number,
  showTimeSeries?: boolean,
}

export interface DefaultReportLayoutConfig {
  layoutName: string;
  selectedTrialName?: string;
  leftTiles?: { type: ChartLayoutTypes; name?: string; id?: string }[];
  rightTiles?: { type: ChartLayoutTypes; name?: string; id?: string }[];
  version?: number;
}

export type ChartLayoutTypes = 'video' | 'chart' | 'multi-chart' | 'clip' | 'image';

export interface ReportLayoutTileId {
  id: string,
  type: ChartLayoutTypes,
}

@Injectable({
  providedIn: 'root'
})
export class LeftRightService {
  private leftTiles: Tile[] = [];
  private rightTiles: Tile[] = [];
  private refLeftTiles: Tile[] = [];
  private refRightTiles: Tile[] = [];
  public leftTileIds: ReportLayoutTileId[] = [];
  public rightTileIds: ReportLayoutTileId[] = [];
  public trialNamesForVideoSync: string[] = [];
  private primaryTrialName: string;
  public maximizer: Subject<void>;
  public isMaximized = false;

  public enabled = false;
  public zoomWindowX: number = -1;
  public lastZoomWindowX: number = -1;
  public xZoomStartStored: number = -1;
  public xZoomOffset: number = -1;
  public zoomRightChart = false;
  public cyclesForSync: string[] = [];
  public tOffsetVideoSync: Record<string, number> = {};
  public cycleTimes: Record<string, string> = {};

  constructor() {
    this.maximizer = new Subject<void>();
  }

  private selectTile(tile: Tile, column: Tile[], deepCopy = false): Tile[] {
    if (this.isTileSelectedInColumn(tile, column)) {
      // Deselect if already selected
      return this.removeTileFromColumn(tile, column);
    }

    if (column.length >= MAX_ROWS) {
      // Pop off the first item;
      column.shift();
    }

    // we keep 2 copies of the tile columns: one contains a deep copy of the single tiles,
    // so that we can operate on the single tile separately, without having copies of the same tiles involved
    if (deepCopy) {
      const deepCopiedTile = JSON.parse(JSON.stringify(tile));
      deepCopiedTile.primaryVideo = false;
      column.push(deepCopiedTile);
    } else {
      column.push(tile);
    }

    return column;
  }

  public selectLeft(tile: Tile): void {
    this.leftTiles = this.selectTile(tile, this.leftTiles, true);
    this.refLeftTiles = this.selectTile(tile, this.refLeftTiles);
  }

  public selectRight(tile: Tile): void {
    this.rightTiles = this.selectTile(tile, this.rightTiles, true);
    this.refRightTiles = this.selectTile(tile, this.refRightTiles);
  }

  private findTileType(tile: Tile): ChartLayoutTypes {
    let type;
    if (this.isTileVideo(tile)) {
      type = 'video';
    } else if (this.isTileClipLoader(tile)) {
      type = 'clip';
    } else if (this.isTileMultiChart(tile)) {
      type = 'multi-chart';
    } else if (this.isTileImage(tile)) {
      type = 'image';
    } else {
      type = 'chart';
    }
    return type;
  }

  public storeInitialTileIds(): void {
    this.leftTileIds = [];
    for (const tile of this.leftTiles) {
      const tileId: ReportLayoutTileId = {
        id: this.isTileVideo(tile) === true || this.isTileClipLoader(tile) === true ? tile.dataUrl : tile.originalId,
        type: this.findTileType(tile)
      };
      this.leftTileIds.push(tileId);
    }

    this.rightTileIds = [];
    for (const tile of this.rightTiles) {
      const tileId: ReportLayoutTileId = {
        id: this.isTileVideo(tile) === true || this.isTileClipLoader(tile) === true ? tile.dataUrl : tile.originalId,
        type: this.findTileType(tile)
      };
      this.rightTileIds.push(tileId);
    }
  }

  public updateTileId(changedTile: TileUpdateIdsAndType, leftRight: 'left' | 'right'): void {
    if (leftRight === 'left') {
      const ids = this.leftTileIds.map(x => x.id);
      const nNewId = ids.reduce((total, x) => (x === changedTile.newId ? total + 1 : total), 0);
      if (nNewId === 0) {
        const tileId = this.leftTileIds.find(item => item.id === changedTile.oldId);
        tileId.id = changedTile.newId;
        if (changedTile.type !== undefined) {
          tileId.type = changedTile.type;
        }
      }
    } else {
      const ids = this.rightTileIds.map(x => x.id);
      const nNewId = ids.reduce((total, x) => (x === changedTile.newId ? total + 1 : total), 0);
      if (nNewId === 0) {
        const tileId = this.rightTileIds.find(item => item.id === changedTile.oldId);
        tileId.id = changedTile.newId;
        if (changedTile.type !== undefined) {
          tileId.type = changedTile.type;
        }
      }
    }
  }

  public isTileSelectedInColumn(tile: Tile, column: Tile[]): boolean {
    if (this.isTileVideo(tile) || this.isTileClipLoader(tile) || this.isTileImage(tile)) {
      return !!column.find(columnTile => columnTile?.dataUrl !== undefined ? columnTile?.dataUrl == tile?.dataUrl : false);
    } else if (this.isTileChart(tile)) {
      return !!column.find(columnTile => columnTile?.originalId !== undefined ? columnTile?.originalId == tile?.originalId : false);
    }
  }

  public getTileIndexInColumn(tile: Tile, column: Tile[]): number {
    return column.findIndex(columnTile => columnTile == tile);
  }

  public isTileSelected(tile: Tile): boolean {
    return this.isTileSelectedInColumn(tile, this.refLeftTiles) || this.isTileSelectedInColumn(tile, this.refRightTiles);
  }

  private removeTileFromColumn(tile: Tile, column: Tile[]): Tile[] {
    let index = -1;
    for (let i = 0; i < column.length; i++) {
      if (this.isTileVideo(tile) || this.isTileClipLoader(tile) || this.isTileImage(tile)) {
        if (tile?.dataUrl !== undefined && column[i].dataUrl === tile.dataUrl) {
          index = i;
          break;
        }
      } else {
        if (tile?.originalId !== undefined && column[i].originalId === tile.originalId) {
          index = i;
          break;
        }
      }
    }

    if (index > -1) {
      column.splice(index, 1);
    }

    return column;
  }

  public getLeftTiles(): Tile[] {
    return this.refLeftTiles;
  }

  public getRightTiles(): Tile[] {
    return this.refRightTiles;
  }

  public getCopiedLeftTiles(): Tile[] {
    return this.leftTiles;
  }

  public getCopiedRightTiles(): Tile[] {
    return this.rightTiles;
  }

  public canMaximize(): boolean {
    return this.leftTiles.length > 0 || this.rightTiles.length > 0;
  }

  /**
   * Maximizes the splitscreen view on top of the current trial/report, if possible
   * @returns true if the splitscreen was maximized, false otherwise
   */
  public maximize(): boolean {
    if (this.canMaximize()) {
      this.storeInitialTileIds();
      this.maximizer.next();
      return true;
    }
    return false;
  }

  public clear(): void {
    this.clearLeftColumn();
    this.clearRightColumn();
  }

  public clearLeftColumn(): void {
    this.leftTiles = [];
    this.refLeftTiles = [];
  }

  public clearRightColumn(): void {
    this.rightTiles = [];
    this.refRightTiles = [];
  }

  public isTileChart(tile: Tile): tile is DataTrack {
    return tile ? 'values' in tile : false;
  }

  public isTileVideo(tile: Tile): tile is MediaData {
    // There are no good discriminant here
    return !this.isTileChart(tile) && !this.isTileClipLoader(tile) && !this.isTileImage(tile);
  }

  public isTileMultiChart(tile: Tile): tile is DataTrack {
    return tile ? 'values' in tile && (tile?.dataType !== undefined && (tile.dataType === 'multi' || tile.dataType === 'multi-report')) : false;
  }

  public isTileClipLoader(tile: Tile): tile is Clip3DData {
    return tile ? 'clipTrcPath' in tile : false;
  }

  public isTileImage(tile: Tile): tile is ImageData {
    return tile ? 'imageSrc' in tile : false;
  }

  // returns true if both a video and a chart are present in leftRight tiles
  public isChartAndVideo(): boolean {
    let hasCharts = false;
    let hasVideos = false;
    this.leftTiles.forEach((tile) => {
      if (tile &&'values' in (tile as DataTrack)) {
        hasCharts = true;
      } else {
        hasVideos = true;
      }
    });
    this.rightTiles.forEach((tile) => {
      if (tile && 'values' in (tile as DataTrack)) {
        hasCharts = true;
      } else {
        hasVideos = true;
      }
    });
    return hasCharts && hasVideos;
  }

  // returns true if we have any charts in the leftRight tiles
  public hasCharts(): boolean {
    return this.leftTiles.some(tile => this.isTileChart(tile)) || this.rightTiles.some(tile => this.isTileChart(tile));
  }

  public containsMultipleVideos(): boolean {
    return [...this.refLeftTiles, ...this.refRightTiles].filter(tile => this.isTileVideo(tile)).length > 1;
  }

  /**
   * Find the first video in the columns and mark it as primary. This is used to dictate which video
   * governs the multi trial video split screen.
   * @see https://gitlab.com/moveshelf/mvp/-/issues/2447
   */
  public markPrimaryVideo(): void {
    let firstTile = this.leftTiles.find(tile => this.isTileVideo(tile)) as MediaData;
    if (!firstTile) {
      firstTile = this.rightTiles.find(tile => this.isTileVideo(tile)) as MediaData;
    }
    if (firstTile) {
      firstTile.primaryVideo = true;
      this.primaryTrialName = firstTile.trialNameInReport;
    }
  }

  public setInitialTrialNamesForVideoSync(): void {
    this.trialNamesForVideoSync = [...this.leftTiles, ...this.rightTiles].map(tile => this.isTileVideo(tile) ? tile?.trialNameInReport : undefined).filter((item) => item);
    this.cyclesForSync = this.getCycleTimesForTrialNames();
  }

  public updateTrialNamesForVideoSync(curClipName: string, newClipName: string): void {
    const idx = this.trialNamesForVideoSync.indexOf(curClipName);
    if (idx !== -1) {
      this.trialNamesForVideoSync[idx] = newClipName;
    }
    this.cyclesForSync = this.getCycleTimesForTrialNames();
  }

  public setVideoOffsetForSync(cycleForSync: string): boolean {
    this.tOffsetVideoSync = {};
    if (this.cyclesForSync.length > 0) {
      // first primary trial
      const tOffsetPrimary = this.getPrimaryOffset(cycleForSync);
      // then the others
      const allCycleTimes = Object.keys(this.cycleTimes);
      for (const trialName of this.trialNamesForVideoSync) {
        if (trialName !== this.primaryTrialName) {
          const myCycle = allCycleTimes.filter(item => item.startsWith(cycleForSync) && item.endsWith(trialName))[0];
          const tOffsetTrial = this.cycleTimes[myCycle] !== undefined && this.cycleTimes[myCycle]['time-start'] !== undefined ? this.cycleTimes[myCycle]['time-start'] : 0;
          this.tOffsetVideoSync[trialName] = tOffsetTrial - tOffsetPrimary;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  public getPrimaryOffset(cycleForSync: string): number {
    const allCycleTimes = Object.keys(this.cycleTimes);
    const primaryCycle = allCycleTimes.filter(item => item.startsWith(cycleForSync) && item.endsWith(this.primaryTrialName))[0];
    return this.cycleTimes[primaryCycle] !== undefined && this.cycleTimes[primaryCycle]['time-start'] !== undefined ? this.cycleTimes[primaryCycle]['time-start'] : 0;
  }

  private getCycleTimesForTrialNames(): string[] {
    if (this.cycleTimes === undefined) {
      return [];
    }

    const allCycleTimes = Object.keys(this.cycleTimes);
    let cycleTimesIntersect = allCycleTimes.filter(item => item.endsWith(this.primaryTrialName)).map(item => item.split(' > ')[0]);
    // first check if we have another trial next to the primary
    if (this.trialNamesForVideoSync.some(item => item !== this.primaryTrialName)) {
      for (const trialName of this.trialNamesForVideoSync) {
        if (trialName !== this.primaryTrialName && cycleTimesIntersect.length > 0) {
          const availableCycleTimes = allCycleTimes.filter(item => item.endsWith(trialName)).map(item => item.split(' > ')[0]);
          cycleTimesIntersect = cycleTimesIntersect.filter(element => availableCycleTimes.includes(element));
        }
      }
      return cycleTimesIntersect;
    } else {
      return [];
    }
  }

  /**
   * Tries to find the saved layout tiles between the available data tracks and video tracks
   * @returns returns true if the layout could be opened in splitscreen, false otherwise
   */
  public loadLayout(savedLayout: ReportLayoutConfig, allDataTracks: DataTrack[][], allVideoTracks: MediaData[][], allClip3dTracks: Clip3DData[], multiChartTiles: Record<string, DataTrack>, imageFiles: string[] = []): boolean {
    if (savedLayout !== undefined) {
      this.clear();
      for (const savedTile of savedLayout.leftTileIds) {
        const tile = this.findTile(savedTile, allVideoTracks, allDataTracks, allClip3dTracks, multiChartTiles, imageFiles);
        if (tile) {
          this.selectLeft(tile);
        }
      }
      for (const savedTile of savedLayout.rightTileIds) {
        const tile = this.findTile(savedTile, allVideoTracks, allDataTracks, allClip3dTracks, multiChartTiles, imageFiles);
        if (tile) {
          this.selectRight(tile);
        }
      }
      const layoutOpenedCorrectly = this.maximize();
      return layoutOpenedCorrectly;
    }
    return false;
  }

  private findTile(tile: ReportLayoutTileId, allVideoTracks: MediaData[][], allDataTracks: DataTrack[][], allClip3dTracks: Clip3DData[], multiChartTiles: Record<string, DataTrack>, imageFiles: string[] = []): Tile {
    if (tile.type === 'video') {
      for (const videoTracksForTrial of allVideoTracks) {
        for (const videoTrack of videoTracksForTrial) {
          if (videoTrack.dataUrl.startsWith('https')) {
            // If we are on moveshelf videotracks dataUrl are https URLs
            const extractBaseURL = (url) => {
              const queryStringIndex = url.indexOf('?');
              return queryStringIndex !== -1 ? url.substring(0, queryStringIndex) : url;
            };
            const baseURL1 = extractBaseURL(videoTrack.dataUrl);
            const baseURL2 = extractBaseURL(tile.id);
            // We need to compare the base URL without the extra query parameters
            if (baseURL1 === baseURL2) {
              return videoTrack;
            }
          } else if (videoTrack.dataUrl === tile.id) {
            // previous logic is kept, this works for Insight
            return videoTrack;
          }
        }
      }
    } else if (tile.type === 'clip') {
      for (const clip3DTrack of allClip3dTracks) {
        if (clip3DTrack.id === tile.id) {
          return clip3DTrack;
        }
      }
    } else if (tile.type === 'multi-chart') {
      for (const multiChartTile in multiChartTiles) {
        const dataTrack = multiChartTiles[multiChartTile];
        if (dataTrack.originalId === tile.id) {
          return dataTrack;
        }
      }
    }else if (tile.type === 'image') {
      for (const imageFile of imageFiles) {
        if (imageFile === tile.id) {
          return {
            imageSrc: imageFile,
            id: imageFile,
            originalId: imageFile,
          };
        }
      }

    } else {
      for (const dataTracksForTrial of allDataTracks) {
        for (const dataTrack of dataTracksForTrial) {
          if (dataTrack.originalId === tile.id) {
            return dataTrack;
          }
        }
      }
    }
    return undefined;
  }

}
