import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../confirmation-dialog.component';
import { DefaultReportLayoutConfig, LeftRightService, ReportLayoutConfig } from '../left-right/left-right.service';


@Component({
  selector: 'app-layoutlist',
  templateUrl: './layoutlist.component.html',
  styleUrls: ['./layoutlist.component.scss']
})
export class LayoutlistComponent {
  @Input() canEdit: boolean = false;
  @Input() layouts: ReportLayoutConfig[];
  @Input() defaultLayouts: DefaultReportLayoutConfig[];
  @Input() spinner: boolean;
  @Output() layoutSelected = new EventEmitter<number>();
  @Output() defaultLayoutSelected = new EventEmitter<number>();
  @Output() layoutDeleted = new EventEmitter<number>();

  private bsModalRef: BsModalRef;
  public expanded = true;

  constructor(
    public leftRightService: LeftRightService,
    private modalService: BsModalService,
  ) { }

  public loadLayout(layoutIndex: number): void {
    this.layoutSelected.emit(layoutIndex);
  }

  public loadDefaultLayout(layoutIndex: number): void {
    this.defaultLayoutSelected.emit(layoutIndex);
  }

  public deleteLayout(layoutIndex: number): void {
    this.layoutDeleted.emit(layoutIndex);
  }

  public toggleExpand(): void {
    this.expanded = !this.expanded;
  }

  public openDeleteConfirmationModal(layoutIndex: number): void {

    const layout = this.layouts[layoutIndex];
    let version_label = '';
    if (layout.version && layout.version > 1) {
      version_label = ` (${layout.version})`;
    }

    const initialState = {
      question: `Are you sure you want to delete the layout ${layout.layoutName + version_label}?`,
      btnCancelText: 'No, cancel',
      btnOkText: 'Yes, delete',
    };
    this.bsModalRef = this.modalService.show(
      ConfirmationDialogComponent, { initialState }
    );
    this.bsModalRef.content.confirmed.subscribe(
      () => this.deleteLayout(layoutIndex)
    );
  }
}
