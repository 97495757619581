import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { GlobalPlaybackControlService } from "app/core/playback-controls/global-playback-control.service";
import { PlaybackControlService } from "app/core/playback-controls/playback-control.service";
import { ForceOverlayCoordinate, VideoForceProjection } from "app/shared/data-helper";
import { LeftRightService } from "app/shared/left-right/left-right.service";
import { TrialChartsService } from "app/shared/multi-chart/trial-charts.service";
import { ColorService } from "app/shared/services/color-service/color-service.service";
import { delay, distinctUntilChanged, firstValueFrom, Subscription, take } from "rxjs";
import { VideoTrackService } from "../media-player/video-tracks.service";
import { VideoForceOverlayService } from "./video-force-overlay.service";
import { OverlayType } from "../media-player/media-player.types";

@Component({
  selector: "app-video-force-overlay",
  templateUrl: "./video-force-overlay.html",
  styleUrls: ["./video-force-overlay.scss"],
  standalone: true,
})
export class VideoForceOverlayComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild("overlay_canvas", { static: true }) canvasRef: ElementRef;

  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private forceData: VideoForceProjection = null;
  private playbackService: PlaybackControlService;

  @Input() public overlayWidth: number;
  @Input() public overlayHeight: number;
  @Input() public overlayType: OverlayType = OverlayType.TrialView;
  @Input() public videoName: string = '';
  @Input() public originalVideoWidth: number;
  @Input() public originalVideoHeight: number;
  @Input() public videoElementHeight: number;
  @Input() public canvasLeftOffset: number;
  @Input() public offsetTop: number;
  @Input() private currentTrialName: string = "";
  @Input() public totalVideoDuration: number = 0;

  public canvasTopPosition: number = 0;

  private subs: Subscription[] = [];

  constructor(
    private playbackGlobal: GlobalPlaybackControlService,
    private videoForceOverlayService: VideoForceOverlayService,
    private colorService: ColorService,
    private leftRightService: LeftRightService,
    private videoTrackService: VideoTrackService,
    private trialChartsService: TrialChartsService,
    ) {
  }

  public ngOnInit(): void {
    this.subs.push(this.playbackGlobal.playbackControl.subscribe((service) => {
      this.playbackService = service;
      this.setPlaybackControl(service);
    }));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.videoName == undefined || this.videoName.indexOf('.overlay.') !== -1) {
      this.videoName = '';
    }
    if (changes.videoName) {
      this.updateOverlayData();
    }

    this.canvasTopPosition = this.offsetTop;
    if (this.videoElementHeight > this.overlayHeight) {
      this.canvasTopPosition += Math.floor((this.videoElementHeight - this.overlayHeight) / 2);
    }
    // when changing to splitview/fullscreen we may have the video not playing, so we need to trigger
    // a drawing with the current time. I also added a delay to wait for the canvas to be properly resized
    // without the small delay it usually happens that the first drawing is wrong or skipped
    if (this.playbackService && !!this.context) {
      this.playbackService.playbackTime.pipe(delay(100), take(1)).subscribe((time: number) => {
        this.timeChanged(time);
      });
    }
  }

  public ngAfterViewInit(): void {
    this.canvas = this.canvasRef.nativeElement;
    this.context = this.canvas.getContext("2d");
    this.subs.push(this.videoForceOverlayService.getForceData().subscribe((data) => {
      this.forceData = data?.find(x => (x.name === this.videoName || this.videoName.includes('.' + x.name + '.')) && x.trialName === this.currentTrialName);
      console.debug("forceData", this.forceData);
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private splitVideoName(): string[] {
    return this.videoName.split(' > ');
  }

  private async updateOverlayData(): Promise<void> {
    const overlayData = await firstValueFrom(this.videoForceOverlayService.getForceData());

    switch (this.overlayType) {
      case OverlayType.TrialView:
        this.forceData = overlayData?.find(x => (x.name === this.videoName || this.videoName.includes('.' + x.name + '.')) && x.trialName === this.currentTrialName);
        break;

      case OverlayType.ReportView:
        /* 
        Video name is "2024-09-27 > Trials > Walking03 > Walking03.2117832.20230427123410.mp4"
        Force overlay data is a list of objects with the following structure:
        {
          name: "2107530",
          trialName: "Walking03",
          forceplate: [
            {
              name: "GRW1.F",
              map: {...},
            },
            ...
          ],
        }

        To get the force overlay data for the video, 
        we need to split the video name and get the trial name i.e. 'Walking03.2117832.20230427123410.mp4'
        Split the trial name and get the trial name and the video name i.e. 'Walking03' and '2117832'
        */

        const name_splitted = this.videoName.split(' > ');
        const name_trial = name_splitted[name_splitted.length - 1].split('.');
      
        this.forceData = overlayData?.find(x => (
          x.fullPath === this.videoName || 
          (name_trial.length > 1 && x.trialName === name_trial[0] && x.name === name_trial[1])
        )); 
        if (this.forceData === undefined) {
          const otherForceData = this.videoForceOverlayService.getOtherForceData();
          if(this.videoForceOverlayService.getOtherForceData().length > 0) {
            this.forceData = otherForceData.find(x => x.fullPath === this.videoName);
          }
        }

        break;

      default:
        console.error("Overlay type not recognized");
    }
    console.debug("forceData", this.forceData);
  }

  private setPlaybackControl(control: PlaybackControlService): void {
    this.subs.push(control.playbackTime.pipe(distinctUntilChanged()).subscribe((time: number) => {
      if (this.context) {
        this.timeChanged(time);
      }
    }));
  }

  private timeChanged(time: number): void {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    const [isVideoPlaying, tOffsetVideoSync] = this.isVideoPlaying(time);
    if (this.forceData && isVideoPlaying) {
      this.drawForceSegment(time + this.videoForceOverlayService.tForceDataOffset + tOffsetVideoSync);
    }
  }

  private isVideoPlaying(time: number): [boolean, number] {
    let tOffsetVideoSync = this.leftRightService.tOffsetVideoSync[this.currentTrialName] !== undefined ? this.leftRightService.tOffsetVideoSync[this.currentTrialName] : 0;
    const videoTracks = this.videoTrackService.getTracks();

    // Check if there are video tracks relevant to the current trial
    if (videoTracks) {
      // Check if the video is from the current trial
      let videoTrack = undefined;

      // Based on the overlay type, find the video track
      switch (this.overlayType) {
        case OverlayType.TrialView:
          // Find the video track based on the video name
          videoTrack = videoTracks.find(x => x.originalFileName === this.videoName);

          if (videoTrack === undefined) {
            //Video not found
            return [false, tOffsetVideoSync];
          }

          break;

        case OverlayType.ReportView:
          videoTrack = videoTracks.find(x => [x.trialNameInReport, x.originalFileName].join(' > ') === this.videoName);
          tOffsetVideoSync = videoTrack !== undefined && this.leftRightService.tOffsetVideoSync[videoTrack.trialNameInReport] !== undefined ? this.leftRightService.tOffsetVideoSync[videoTrack.trialNameInReport] : 0;
          if (videoTrack === undefined) {
            // Video not found but it could be from another trial
            videoTrack = this.videoTrackService.getOtherTrialTracks().find(x => [x.trialNameInReport, x.originalFileName].join(' > ') === this.videoName);
            tOffsetVideoSync =  videoTrack !== undefined && this.leftRightService.tOffsetVideoSync[videoTrack.trialNameInReport] !== undefined ? this.leftRightService.tOffsetVideoSync[videoTrack.trialNameInReport] : 0;

            if (videoTrack === undefined) {
              // Video not found
              return [false, tOffsetVideoSync];
            }
          }

          break;

        default:
          console.error("Overlay type not recognized");
          return [false, tOffsetVideoSync];
      }

      const timeOffset = videoTrack?.timeOffset ? videoTrack.timeOffset : 0;
      const videoTime = time + timeOffset + tOffsetVideoSync - this.trialChartsService.playbarOffset;
      return [videoTime > 0 && videoTime <= (this.totalVideoDuration - this.trialChartsService.playbarOffset), tOffsetVideoSync];

    } else {
      return [false, tOffsetVideoSync];
    }
  }

  private drawForceSegment(time: number): void {
    time = Math.floor(time * 100) / 100;
    // TODO: change color/vector for each forceplate
    for (const forceplate of this.forceData.forceplate) {
      const currentCoordinate = forceplate.map.get(time);
      if (currentCoordinate) {
        this.drawVector(currentCoordinate);
      }
    }
  }

  private drawVector(data: ForceOverlayCoordinate): void {
    this.context.save();
    this.context.beginPath();
    this.context.lineCap = "round";
    this.context.lineWidth = 2;
    this.context.strokeStyle = this.colorService.videoForceOverlayColor;
    const xScale = this.originalVideoWidth ? this.canvas.width/this.originalVideoWidth : 1;
    const yScale = this.originalVideoHeight ? this.canvas.height/this.originalVideoHeight : 1;
    const arrowStart = {x: data.x_start*xScale, y: data.y_start*yScale};
    this.context.moveTo(arrowStart.x, arrowStart.y);
    this.context.lineTo(data.x_end*xScale, data.y_end*yScale);
    this.context.stroke();
    this.context.restore();
  }

}
