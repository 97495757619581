import { Session } from "app/projects/patient-view/create-session/session.types";
import { DirectoryOptions } from "app/projects/project-info/project-info-data.service";
import { InvalidTrialData } from "app/projects/trial-template.service";
import { UploadOptionEnum } from './upload-options.enum';
import { Trial } from "./vicon-directory.service";
import { MetadataObject } from "../services/metadata.service";

export type JSONString = string;

export class FileWrapper {
  file: File;
  name: string;
  path: string;
}


export interface SessionUploadConfig {
  trialSideSelection?: Record<string, string>; // <trialId, side>: defines which side is selected for each trial
  invalidTrials?: Record<string, InvalidTrialData>; // <trialId, InvalidTrialData>: for a trial, flag invalid data (kinematics, kinetics, emg, etc...)
  representativeTrials?: Record<string, string>; // <trialId, RepresentativeTrialData>: defines which trials are representative for which sides
  conditionDefinition?: Record<string, string[]>;  // <condition Name, [trial names]>: defines which conditions are associated with which trials
  sessionMetadata?: any; // TODO: add type: pretty long as there are a lot of params, so not done for now
  subjectMetadata?: MetadataObject; // TODO: add type: pretty long as there are a lot of params, so not done for now
  interventionMetadata?: any; // TODO: add type: pretty long as there are a lot of params, so not done for now
}


export abstract class DirectoryUploadService {

  protected knownVideoExtensions: string[] = ['mp4', 'mov', 'avi', 'mkv', 'wmv', 'flv', 'webm', 'm4v', 'mpeg', 'mpg', '3gp'];
  protected uploadOption: UploadOptionEnum;
  protected uploadConfig: SessionUploadConfig = {
    trialSideSelection: undefined,
    conditionDefinition: undefined,
    subjectMetadata: undefined,
    sessionMetadata: undefined,
    interventionMetadata: undefined,
    invalidTrials: undefined,
    representativeTrials: undefined
  };

  protected async delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  protected isFileSuffixValid(filename: string, suffixes: string[]): boolean {
    return suffixes.some(suffix => filename.toLowerCase().endsWith(suffix.toLowerCase()));
  }

  abstract getProviderName(): string;

  abstract getExpectedUploads(): Promise<File[]>;

  abstract upload(session: Session): Promise<void>;

  abstract getSortedConditions(): Promise<string[]>;

  abstract getTrials(): Promise<Trial[]>;

  abstract setFiles(newFiles: File[]): void;

  abstract setOptions(options: DirectoryOptions): void;

  /**
   * Resets the upload configuration to its default values so that the user can
   * start over with a clean slate.
   * NOTE: it might be worth moving this in the subclasses
   */
  public resetUploadConfig(): void {
    this.uploadConfig = {
      trialSideSelection: undefined,
      conditionDefinition: undefined,
      sessionMetadata: undefined,
      subjectMetadata: undefined,
      interventionMetadata: undefined,
      invalidTrials: undefined,
      representativeTrials: undefined
    };
  }

  /**
   * Sets the upload configuration for the current session
   * NOTE: it might be worth moving this in the subclasses
   * @param uploadConfig 
   */
  public setUploadConfig(uploadConfig: SessionUploadConfig): void {
    this.uploadConfig = uploadConfig;
  }

  /**
   * Returns the upload configuration for the current session
   * NOTE: it might be worth moving this in the subclasses
   * @returns the upload configuration
   */
  public getUploadConfig(): SessionUploadConfig {
    return this.uploadConfig;
  }

  /**
   * Splits a file array into videos and non video files. Related issue: https://gitlab.com/moveshelf/mvp/-/issues/2501
   * @param files the array of files to split. This can be either File[] or FileWrapper to deal with both Vicon or
   * Noraxon directory services, which for some reason deal with files with different types.
   * @returns an object where video and non-video files have been separated.
   */
  protected splitFilesByType<T extends File | FileWrapper>(files: T[]): { videos: T[], nonVideos: T[] } {
    const videos: T[] = [];
    const nonVideos: T[] = [];
    files.forEach(file => {
      if (this.isVideoFile(file.name)) {
        videos.push(file);
      } else {
        nonVideos.push(file);
      }
    });
    return { videos, nonVideos };
  }

  /**
   * Checks if a filename represents a video file based on a list of known video extensions
   * @param filename the file name to check (eg: myvideofile.avi)
   * @returns true if extension is a known video extensions, false otherwise
   */
  protected isVideoFile(filename: string): boolean {
    const extension = filename.split('.').pop().toLowerCase();
    return extension ? this.knownVideoExtensions.includes(extension) : false;
  }

  /**
   * Sets the upload option
   * @param option the upload option to set
   */
  public setUploadOption(option: UploadOptionEnum): void {
    this.uploadOption = option;
  }

  protected fileWrap(newFiles: File[]): FileWrapper[] {
    const fileWraps: FileWrapper[] = [];
    for (const file of newFiles) {
      let tempPath: string = '';
      if (file['webkitRelativePath'] && file['webkitRelativePath'] !== '') {
        tempPath = file['webkitRelativePath'];
      } else {
        tempPath = file['path'];
      }
      const pathEnding: string = tempPath.split('/').pop();
      if (pathEnding !== '') {
        tempPath = tempPath.slice(0, tempPath.lastIndexOf('/') + 1);
      }
      const fileWrap = new FileWrapper();
      fileWrap.file = file;
      // In case of `data` files, rename them with their immediate parent folder name and `noraxon` extension
      fileWrap.name = file.name === 'data' ? tempPath.split('/').at(-2) + '.noraxon' : file.name;
      fileWrap.path = tempPath;
      fileWraps.push(fileWrap);
    }
    return fileWraps;
  }

}
