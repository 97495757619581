import {
  AfterViewInit, Component, ElementRef, HostListener, Input, NgZone, OnChanges, OnDestroy, SimpleChanges, ViewChild
} from '@angular/core';
import { ColorService } from 'app/shared/services/color-service/color-service.service';
import { KeyboardHandlerService } from 'app/shared/services/keyboard-handler.service';
import { Subscription, fromEvent, merge } from 'rxjs';
import {
  filter, finalize, map,
  startWith, switchMap, takeUntil,
  tap
} from 'rxjs/operators';
import { PlaybackControlService } from '../../../core/playback-controls/playback-control.service';
// import { Timecode } from 'timecode';
import { PlaybackMode } from '../../../core/playback-mode.enum';
import { TimeSelection } from '../../../core/time-selection';
import { TimecodeService } from '../../../core/timecode.service';
import { timebarColors } from '../clip-controls.component';


interface TapEvent extends HammerInput {
  tapCount: number;
}

@Component({
  selector: 'app-timebar',
  templateUrl: './timebar.component.html',
  styleUrls: ['./timebar.component.scss']
})
export class TimebarComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() allowSelectionEditing = false;
  private _timeMarks: any[] = [];
  @Input() set timeMarks( marks: any[] ) {
    this._timeMarks = marks;
    this.requestRedraw();
  }

  @Input() set minFramerate(framerate: number) {
    this.playbackControl.setMinFrameRate(framerate);
  };

  @Input() timebarColors: timebarColors = {
    playbarEventLeft: this.colorService.playbarEventLeft,
    playbarEventLeftTransparent: this.colorService.playbarEventLeftTransparent,
    playbarEventRight: this.colorService.playbarEventRight,
    playbarEventRightTransparent: this.colorService.playbarEventRightTransparent
  };

  @ViewChild('canvas', { static: true }) canvasRef: ElementRef;

  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private selection: TimeSelection;
  private subs: Subscription[] = [];
  private isHovering: boolean = false;
  private isDragging: boolean = false;
  private shortcutKeyDown: boolean = false;
  private wasPlaying: boolean = false;
  public hoverTimecode: string = undefined;
  public hoverTime: number = 0;
  private lastTimebarTime: number;

  constructor(
    private zone: NgZone,
    private playbackControl: PlaybackControlService,
    private timecodeService: TimecodeService,
    private colorService: ColorService,
    private keyboardHandlerService: KeyboardHandlerService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.playbackControl.getMinFrameRate() > 0) {
      this.playbackControl.setTimeJumpSeconds(1 / this.playbackControl.getMinFrameRate());
    } else {
      this.playbackControl.setTimeJumpSeconds(0.020);
    }
  }

  ngAfterViewInit(): void {
    this.canvas = this.canvasRef.nativeElement;
    this.context = this.canvas.getContext('2d');

    this.configureInputHandling();

    this.subs.push(
      this.playbackControl.playbackTime.subscribe(time => this.draw(time))
    );

    this.subs.push(
      this.playbackControl.selection.subscribe(s => {
        this.selection = s;
        this.draw(this.playbackControl.playbackTime.value);
      })
    );

    this.subs.push(
      this.playbackControl.events.subscribe(events => {
        this._timeMarks = events;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  getTimeFromEventX(xCoord: number): number {
    const offset = xCoord - this.canvas.getBoundingClientRect().left;
    const clampedOffset = Math.min(Math.max((offset / this.canvas.width), 0), 1);
    return clampedOffset * this.playbackControl.timebarDuration;
  }

  private configureInputHandling() {
    const hammer = new Hammer(this.canvas);
    hammer.get('press').set({ time: 500 });
    const panstart = fromEvent(hammer, 'panstart');
    const panmove = fromEvent(hammer, 'panmove');
    const panend = fromEvent(hammer, 'panend');
    const taps = fromEvent(hammer, 'tap');
    const press = fromEvent(hammer, 'press');
    const pressup = fromEvent(hammer, 'pressup');

    const convertEventPositionToTime = (ev: HammerInput) => {
      const cursorTime = this.getTimeFromEventX(ev.center.x);
      // only allow for increments of timeJump if we have timeJump set and we have videos available.
      const timeJumpSeconds = this.playbackControl.getTimeJumpSeconds();
      const time = timeJumpSeconds && this.playbackControl.hasVideo() ? Math.round(cursorTime/timeJumpSeconds) * timeJumpSeconds : cursorTime;

      if (this.lastTimebarTime === time) {
        this.playbackControl.stopSeeking();
      }

      this.lastTimebarTime = time;
      return time;
    };

    const seektime = merge(
      taps,
      panstart.pipe(
        tap(()=> this.playbackControl.startSeeking()),
        switchMap((ev: HammerInput) => panmove.pipe(
          startWith(ev),
          takeUntil(panend),
          finalize(() => this.playbackControl.stopSeeking())
        )),
      )
    ).pipe(
      map(convertEventPositionToTime),
    );

  seektime.subscribe(this.playbackControl.playbackTime);

    const selection = press.pipe(
      tap(() => { this.setCursor('col-resize'); this.isDragging = true; }),
      map(convertEventPositionToTime),
      switchMap((dragStart: number) => {
        const existingSelection = this.selection;
        return panmove.pipe(
          map(convertEventPositionToTime),
          map(dragEnd => {
            if (existingSelection) {
              const testSelectionChange = (end: string) => {
                const selectionPos = this.convertTimeToPos(existingSelection[end]);
                const dragStartPos = this.convertTimeToPos(dragStart);
                const selectionThresholdPixels = 16;
                return Math.abs(selectionPos - dragStartPos) < selectionThresholdPixels;
              };
              if (testSelectionChange('start')) {
                return new TimeSelection(dragEnd, this.selection.end);
              }
              if (testSelectionChange('end')) {
                return new TimeSelection(this.selection.start, dragEnd);
              }
            }
            return new TimeSelection(dragStart, dragEnd);
          }),
          takeUntil(merge(panend, pressup)),
          finalize(() => { this.setCursor('default'); this.isDragging = false; })
        );
      }
      )
    );

    const clearSelection = taps.pipe(
      filter((ev: TapEvent) => ev.tapCount === 2),
      map(() => null)
    );

    if (this.allowSelectionEditing) {
      merge(selection, clearSelection).subscribe(this.playbackControl.selection);
    }
  }

  private setCursor(cursor: string) {
    document.body.style.cursor = cursor;
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: any): void {
    if (this.keyboardHandlerService.isEventFromInput(event)) {
      return;
    }
    if ( (event.charCode ? event.charCode : event.keyCode) == 32) {
      this.playbackControl.togglePlayPause();
      event.preventDefault();
    }
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:keydown', ['$event'])
  handleKeyDownUpEvent(event: any): void {
    if (this.keyboardHandlerService.isEventFromInput(event)) {
      return;
    }
    if (event.shiftKey != this.shortcutKeyDown) {
      this.shortcutKeyDown = event.shiftKey;
      if (this.shortcutKeyDown) {
        this.wasPlaying = this.playbackControl.mode.value == PlaybackMode.Playing;
        if (this.wasPlaying)
          this.playbackControl.pause();
      } else {
        if (this.wasPlaying) {
          this.playbackControl.play();
          this.wasPlaying = false;
        }
      }
    }

    const x = event.key;

    if (this.shortcutKeyDown) {
      const sel = new TimeSelection(this.hoverTime, this.hoverTime);
      switch (x.toLowerCase()) {
        case 'i':
          if (this.selection)
            sel.end = this.selection.end;

          this.playbackControl.selection.next( sel );
        break;
        case 'o':
          if (this.selection)
            sel.start = this.selection.start;

          this.playbackControl.selection.next( sel );
        break;
      }
    } else {
      if ( event.type == "keydown") {
        switch (x.toLowerCase()) {
          case 'j':
          case 'arrowleft':
            this.playbackControl.timeDeltaMove(-this.playbackControl.getTimeJumpSeconds());
            break;
          case 'l':
          case 'arrowright':
            this.playbackControl.timeDeltaMove(this.playbackControl.getTimeJumpSeconds());
            break;
          case 'k':
            this.playbackControl.togglePlayPause();
            break;
        }
      }
    }
  }

  @HostListener('mousemove', ['$event']) onMousemove(event: MouseEvent) {
    if (this.isHovering && !this.isDragging) {
      const existingSelection = this.selection;
      if ((existingSelection && (this.testMouseHoverNob(event, existingSelection.start) ||
          this.testMouseHoverNob(event, existingSelection.end))) ||
          this.testMouseHoverNob(event, this.playbackControl.playbackTime.value))
        this.setCursor('pointer');
      else
        this.setCursor('default');
    }
    if (this.isHovering) {
      this.hoverTime = this.getTimeFromEventX(event.x);
      this.hoverTimecode = this.timecodeService.getTimecode(this.hoverTime);
      if (this.shortcutKeyDown)
        this.playbackControl.playbackTime.next(this.hoverTime);
    }
  }

  private testMouseHoverNob(event: MouseEvent, nobTime: number) {
    const relativeX = event.x - this.canvas.getBoundingClientRect().left;
    const selectionPos = this.convertTimeToPos(nobTime);
    const selectionThresholdPixels = 16;
    return Math.abs(relativeX - selectionPos) < selectionThresholdPixels;
  }

  @HostListener('mouseover', ['$event']) onHover(event: MouseEvent): void {
    this.isHovering = true;
  }

  @HostListener('mouseout') onMouseOut(): void {
    this.isHovering = false;
    if (!this.isDragging)
      this.setCursor('default');
  }

  @HostListener('window:resize')
  private requestRedraw() {
    requestAnimationFrame(() => this.draw(this.playbackControl.playbackTime.value));
  }

  private draw(time: number) {
    this.resizeCanvas();

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    if (this.playbackControl && this.playbackControl.timebarDuration > 0 && this.playbackControl.clipDuration > 0) {
      let ratio = this.playbackControl.clipDuration / this.playbackControl.timebarDuration;
      if (ratio > 1)
        ratio = 1;

      this.drawTimeline( ratio * 0.8, 1, '#787878');
      this.drawTimeline(0, ratio, this.colorService.playbarMiddleBar);
    } else
      this.drawTimeline(0, 1, this.colorService.playbarMiddleBar);

    for (const mark of this._timeMarks) {
      let color = '#00cdc9';
      if (mark.context == 'Left' || mark.context == 'left')
        color = mark.name.toLowerCase() == "foot strike" || mark.name.toLowerCase() == "cycle start" ? this.timebarColors.playbarEventLeft : this.timebarColors.playbarEventLeftTransparent;
      if (mark.context == 'Right' || mark.context == 'right')
        color = mark.name.toLowerCase() == "foot strike" || mark.name.toLowerCase() == "cycle start" ? this.timebarColors.playbarEventRight : this.timebarColors.playbarEventRightTransparent;

      this.drawTimeMarker(mark.time, color);
    }
    this.drawMainSelection();
    this.drawTimeMarker(time, this.colorService.playbarEventCurrentTime);
  }

  private resizeCanvas() {
    const width = this.canvas.offsetWidth;

    if (this.canvas.width !== width) {
      this.canvas.width = width;
    }
  }

  private drawTimeline(start: number, end: number, color: string) {
    const vertCenter = this.canvas.height / 2;
    this.context.save();
    this.context.beginPath();
    this.context.strokeStyle = color;
    this.context.lineWidth = 8;
    const capSize = this.context.lineWidth / 2;
    this.context.lineCap = 'round';
    this.context.moveTo(capSize + start * this.canvas.width, vertCenter);
    this.context.lineTo(this.canvas.width * end - capSize, vertCenter);
    this.context.stroke();
    this.context.restore();
  }

  private drawTimeMarker(time: number, color: string = '#00cdc9') {
    const x = this.convertTimeToPos(time);
    this.drawMarker(x, color);
  }

  private drawMainSelection() {
    this.drawSelection(this.selection);
  }

  private drawSelection(selection: TimeSelection, color: string = '#5850ff') {
    if (selection) {
      const start = this.convertTimeToPos(selection.start);
      const end = this.convertTimeToPos(selection.end);
      this.context.save();
      this.context.fillStyle = color + '88';
      this.context.rect(start, 0, end-start, this.canvas.height);
      this.context.fill();
      this.context.restore();
      this.drawMarker(start, color);
      this.drawMarker(end, color);
    }
  }

  private convertTimeToPos(time: number): number {
    return (time / this.playbackControl.timebarDuration) * this.canvas.width;
  }

  private drawMarker(x: number, strokeStyle: string) {
    this.context.save();
    this.context.beginPath();
    this.context.strokeStyle = strokeStyle;
    this.context.lineCap = 'round';
    this.context.lineWidth = 8;
    const capSize = this.context.lineWidth / 2;
    this.context.moveTo(x, capSize);
    this.context.lineTo(x, this.canvas.height - capSize);
    this.context.stroke();
    this.context.restore();
  }

}
