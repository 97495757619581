import { Injectable } from '@angular/core';
import { LRUMap } from 'lru_map';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ClipOptionsService {

  additionalClipsId: BehaviorSubject<string[]> = new BehaviorSubject([]);
  clipId: string;
  options: LRUMap<string, any>;

  chartSelectedId: BehaviorSubject<string> = new BehaviorSubject("");
  dataCharts: string[] = [];
  multiChart: boolean = false;

  constructor() {}

  setClipId(clipId: string) {
    if (clipId != this.clipId) {
      this.additionalClipsId.next([]);
      this.dataCharts = [];
      this.multiChart = false;
      this.options = new LRUMap(10);
    }
    this.clipId = clipId;
  }

  addClipById(clipId: string) {
    if (clipId == this.clipId)
      return;

    const clips = this.additionalClipsId.getValue();
    console.log(clips.indexOf(clipId) == -1);
    if (clips.indexOf(clipId) == -1) {
      clips.push(clipId);
      this.setDefaultOptions(clipId);
      this.additionalClipsId.next(clips);
    }
  }

  setDefaultOptions(clipId: string) {
    this.options.set(clipId, {visibility: true});
  }

  getVisibility(id: string): boolean {
    const options = this.options.get(id);
    if (options && options.visibility != undefined)
      return options.visibility;
    else
      return undefined;
  }

  getOptions(id: string) {
    return this.options.get(id);
  }

  setDataCharts(list: string[], selected?: string) {
    this.dataCharts = list;
    let count = 0;
    for (const t of list) {
      for (const tt of (t as any).tracks) {
        count++;
        if (count > 1) {
          this.multiChart = true;
          //return;
        }
      }
    }
    if (count <= 1)
      this.multiChart = false;

    if (selected)
      this.selectChart(selected);
  }

  selectChart(id: string) {
    this.chartSelectedId.next(id);
  }
}
