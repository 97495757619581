import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from 'app/projects/patient-view/create-session/session.types';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProgressbarType } from 'ngx-bootstrap/progressbar';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';
import { AdditionalDataInfo, AdditionalDataService } from '../core/additional-data.service';
import { AuthService } from '../core/auth.service';
import { ClipOptionsService } from '../core/clip-options.service';
import { ErrorInfo } from '../core/clip-uploader.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog.component';
import { FeatureFlagsService } from './services/feature-flags.service';
import { ClipLoaderService } from 'app/moveshelf-3dplayer/clip-loader/clip-loader.service';
import { TrialTemplateService } from 'app/projects/trial-template.service';
import { ClipParsingOptions } from './multi-chart/trial-charts.service';


@Component({
  selector: 'app-media-tracks-manager',
  templateUrl: './media-tracks-manager.component.html',
  styleUrls: ['./media-tracks-manager.component.scss'],
  providers: [FeatureFlagsService],
})
export class MediaTracksManagerComponent implements OnInit {

  private _clipId = new BehaviorSubject<string>(null);
  @Input() set clipId(id: string) {
    this._clipId.next(id);
  }
  get clipId(): string {
    return this._clipId.getValue();
  }
  @Input() parentClipSelectionEvent: EventEmitter<string>;
  @Input() parentClipDeSelectionEvent: EventEmitter<string>;

  @Output() dataSelection = new EventEmitter<AdditionalDataInfo>();
  @Output() dataDeletion = new EventEmitter<string>();

  @Input() sessionChanges: Observable<Session>;
  @Input() hideDragAndDrop: boolean = false;

  public data: AdditionalDataInfo[];
  public progess: Map<string, number> = new Map();
  private subs: Subscription[] = [];

  private maxNoData: number = 2;
  private maxFileSize: number = 25 * 1024 * 1024;
  private maxFileSizeTeam: number = 100 * 1024 * 1024;
  private currentSession: Partial<Session>;

  public errors: Array<ErrorInfo> = [];
  public gcdContext: Record<string, string> = {};

  public basicFileTypes = ".mp4,.mov,.mpg,.avi,.bvh,.trc,.fbx,.glb,.mox,.mvnx,.csv,.hm,.mkv";
  public supportedFileTypes = this.basicFileTypes;

  description = 'Drag and drop or click to upload videos or other supported data';
  tracks_title ='Available data';
  private clipStatusComplete: boolean = false;

  bsModalRef: BsModalRef;
  public additionalDataSelectionEnabled = false;

  constructor(
    private dataService: AdditionalDataService,
    private optionsService: ClipOptionsService,
    private auth: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private readonly featureFlagsService: FeatureFlagsService,
    protected readonly clipLoaderService: ClipLoaderService,
    protected readonly trialTemplateService: TrialTemplateService,
  ) {}

  ngOnInit() {
    if (!!this.featureFlagsService.get('canPerformBiomechEvaluation') === true) {
      if (this.router.url.indexOf('session') !== -1 ) {
        this.additionalDataSelectionEnabled = true;
      }
      this.parentClipSelectionEvent?.subscribe(selected => {
        if (selected === this.clipId) {
          const gcdsWithContext = Object.keys(this.gcdContext).length > 0;
          for (const data of this.data) {
            if (gcdsWithContext) {
              if (this.gcdContext[data.id] !== undefined && this.gcdContext[data.id].length > 0) {
                data.selected = true;
              } else {
                data.selected = false;
              }
            } else {
              data.selected = true;
            }
            this.dataSelection.emit(data);
          }
        }
      });
      this.parentClipDeSelectionEvent?.subscribe(selected => {
        if (selected === this.clipId) {
          for (const data of this.data) {
            data.selected = false;
            this.dataSelection.emit(data);
          }
        }
      });
    }

    this.subs.push(this.getAdditionalDataSubscription());

    this.subs.push(this.dataService.monitorProgress().subscribe( (prog) => {
      if (prog.currentUploadId != null)
        this.progess.set(prog.currentUploadId, prog.progress);
    }));

    if (this.canUploadRawData()) {
      this.maxFileSize = this.maxFileSizeTeam * 1024 * 1024;
      this.maxNoData = 100;
      this.supportedFileTypes = this.basicFileTypes + ",.raw,.zip,.fcd,.xml,.pdf,.png,.jpg,.gif,.c3d,.xlsx,.xcp,.pdf,.json,.jpg,.png,.txt,.gcd,.noraxon,.xcp";
      this.description = 'Drag and drop or click to upload videos, supported or raw data';
    }
  }

  /**
   * Get a subscription provider for the data contained in this componente. It can either be:
   *
   * * grouped: coming as input, it means that a parent component is already polling on this trial's data and can provide it
   * * standalone: old logic, this component will autonomously poll for its clip's data
   */
  private getAdditionalDataSubscription(): Subscription {
    if (this.sessionChanges) {
      // A parent component is providing an observable we can attach to
      return this.sessionChanges.subscribe(newSession => {
        if (!newSession) return;
        this.dataUpdates(newSession);
      });
    } else {
      // There is no parent component that can provide live data
      return this._clipId.pipe(
        switchMap(clipId => this.dataService.fetchAdditionalDataInfo(clipId)))
        .subscribe(data => this.dataUpdates({clips: [{id: this.clipId, additionalData: data}]})
      );
    }
  }

  canUploadRawData() {
    return this.auth.isTeamAuthenticated() || this.auth.isTesterAuthenticated() || this.auth.isEnterpriseAuthenticated();
  }

  isDataForTestersOnly(data: AdditionalDataInfo): boolean {
    return data.originalFileName.indexOf('json') !== -1 && this.auth.isTesterAuthenticated();
  }

  dataUpdates(session: Partial<Session>): void {
    this.currentSession = session;
    this.errors = [];
    this.data = [];
    const clip = session.clips?.find(clip => clip.id === this.clipId);
    const customOptionsObject = clip?.customOptions ? JSON.parse(clip.customOptions) : {};

    if (customOptionsObject?.trialTemplate?.sideAdditionalData !== undefined) {
      this.gcdContext =  customOptionsObject.trialTemplate.sideAdditionalData;
    }
    if (!clip || !clip.additionalData) {
      console.error('Clip not found in session data');
      return;
    }
    for (const data of clip.additionalData) {
      // ADDED CSV TO SHOW FILE
      if (data.dataType === 'video' || data.dataType === 'motion' || data.dataType === 'doc' || data.dataType === 'raw' || data.dataType == 'camera'
        || data.originalFileName.indexOf('txt') !== -1 || data.originalFileName.indexOf('csv') !== -1 || this.isDataForTestersOnly(data)
      ) {
        this.data.push({ ...data, selected: false });
      }
    }

    {
      let allComplete = true;
      for (const data of clip.additionalData) {
        if (data.uploadStatus != 'Complete') {
          allComplete = false;
          break;
        }
      }
      if (allComplete) {
        // check if clip exists in cache and add if not yet in cache
        this.clipStatusComplete = true;
        if (!this.clipLoaderService.clipExistsInCache(clip.id)) {
          const updateClipOptions = true;
          const forceReload = true;
          const defaultTrialTemplate = this.trialTemplateService.mergedTrialTemplateAsJSON(this.featureFlagsService.getAll(), undefined);
          const options: ClipParsingOptions = {
            skipMox: !!this.featureFlagsService.get('skipMoxDownloadAndParseForCharts'),
            fetchTimeBasisCharts: false,
            cameraNamesForDlt: defaultTrialTemplate?.cameraNamesForDltMox,
            applyRegularGcdCycles: !!this.featureFlagsService.get('canPerformBiomechEvaluation') === false,
          };
          this.clipLoaderService.loadClipDataAndUpdateCache(clip.id, options, updateClipOptions, forceReload);
        }
      } else if (!allComplete && this.clipStatusComplete) {
        // clip was complete and has changed, so we delete it from cache.
        this.clipStatusComplete = false;
        this.clipLoaderService.deleteClipFromCache(clip.id);
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  extensionCheck(filename: string, exts: string[]) {
      for (const ext of exts) {
        if (filename.indexOf(ext) != -1)
          return true;
      }

      return false;
  }

  public readableUploadStatus(uploadStatus: string) {
    const statusMap = new Map([
      ['Pending', 'Uploading'],
      ['Processing', 'Processing'],
      ['Failed', 'Failed'],
      ['Complete', '']
    ]);
    return statusMap.get(uploadStatus);
  }

  public getProgressValue(item: AdditionalDataInfo): number {
    let value;
    switch (item.uploadStatus) {
      case 'Processing':
        if (this.progess.has(item.originalFileName)) {
          this.progess.delete(item.originalFileName);
        }
        return 1;
      case 'Complete':
      case 'Failed':
        return 1;
      default:
        value = this.progess.get(item.originalFileName);
        return value ? value : 0;
    }
  }

  isSupported(filename: string): boolean {
    const supportedExt = this.supportedFileTypes.split(',');
    return this.extensionCheck(filename, supportedExt);
  }

  addFiles(files) {
    files = Array.from(files); // Handle FileList

    if (this.data.length > (this.maxNoData - files.length)) {
      this.errors.push(new ErrorInfo("Your remaining quota allows to upload " + (this.maxNoData - this.data.length) + ' files'));
      return;
    }

    files.forEach(f => {
      if (f.size > this.maxFileSize) {
        this.errors.push(new ErrorInfo("Your maximum allowed size for each file is: " + (this.maxFileSize/1024/1024).toFixed(0) + 'MB'));
        return;
      }


      const filename = f.name.toLowerCase();

      if (!this.isSupported(filename)) {
        this.errors.push(new ErrorInfo(f.name + " is not recognized as supported format"));
        return;
      }

      this.dataService.uploadAdditionalDataWithoutType(this.clipId, f).subscribe();
    });
  }

  rejectFiles(files) {
    files = Array.from(files);
    files.forEach(f => this.errors.push(new ErrorInfo(f.name + " is not recognized as supported media format") ));
  }

  getUsage() {
    return this.data.length / this.maxNoData;
  }

  public clearError(index: number) {
    this.errors.splice(index, 1);
  }

  deleteData(dataId: string) {
    for (let i = 0; i < this.data.length; i++ ) {
      if (this.data[i].id === dataId) {
        this.dataDeletion.emit(this.data[i].originalFileName);
      }
    }
    this.dataService.deleteAdditionalData(this.clipId, dataId).subscribe();
  }

  openDeleteConfirmationModal(dataId: string) {
    const initialState = {
      question: 'Are you sure you want to delete this file?',
      btnCancelText: 'No, cancel',
      btnOkText: 'Yes, delete',
    };
    this.bsModalRef = this.modalService.show(ConfirmationDialogComponent, { initialState });
    this.bsModalRef.content.confirmed.subscribe(() => this.deleteData(dataId));
  }

  public getProgressBarStatus(uploadStatus: string): ProgressbarType {
    switch (uploadStatus) {
      case 'Complete':
        return 'success';
      case 'Failed':
        return 'danger';
      case 'Processing':
        return 'warning';
      default:
        return 'success';
    }
  }

  public getSelectData(): AdditionalDataInfo[] {
    return this.data.filter(data => data.selected === true);
  }

  public selectData(item: AdditionalDataInfo): void {
    item.selected = !item.selected;
    this.dataSelection.emit(item);
  }

  public async downloadAdditionalData(additionalDataId: string): Promise<void> {
    const downloadURL = await this.dataService.getAdditionalDataDownloadURI(additionalDataId);
    window.location.href = downloadURL;
  }

  public canEditSession(): boolean {
    return this.currentSession.project?.canEdit === true;
  }

  public isGaitAnalysisReportProcessing(item: AdditionalDataInfo): boolean {
    return item.uploadStatus === 'Processing' && item.originalFileName.includes('GaitAnalysisReport');
  }
}
