import { Injectable } from '@angular/core';
import { DataZoomComponentOption, EChartsOption, SeriesOption } from 'echarts';
import { ReplaySubject } from 'rxjs';
import { DataContext, DataHelper } from '../data-helper';
import { TrialChartsService } from '../multi-chart/trial-charts.service';
import { ColorService } from '../services/color-service/color-service.service';
import { EnvironmentService } from '../services/environment.service';
import { ChartDataTypes, Cycle, DataTrack, PolygonPoint, Sample, ScatterPointValues, SwingTransition } from './chart.types';
import { EChartsHighlightService } from './echarts-highlight.service';
import { EChartsLegendService } from './echarts-legend.service';
import { ReferenceCoordinate } from './emg-overlay/emg-reference-bars.service';

export enum ChartResolution {
  DEFAULT = 1500, // default resolution for all charts
  HIGH = ChartResolution.DEFAULT * 10, // higher resolution used during zoom events
  HIGHEST = ChartResolution.DEFAULT * 100, // highest resolution, used only in specific projects
}

interface InitialZoom {
  xZoomStartValue: number;
  xZoomEndValue: number;
  yZoomStartValue: number;
  yZoomEndValue: number;
}

export interface ChartConfig {
  leftColor: string,
  rightColor: string,
  neutralColor: string,
  referenceColor: string,
  activeCycleWidth: number,
  inactiveCycleWidth: number,
}

/**
 * This service, for which createChart is the entry point, takes a DataTrack,
 * and transforms it into an EChartsOption, the format that ECharts expects.
 *
 * A DataTrack is our internal format which specifies the requested line chart.
 * It can contain data both on a time axis, or gait cycles axis. See
 * chart.types.ts for some details.
 */
@Injectable({
  providedIn: 'root'
})
export class EChartsService {
  public animationsEnabled = true;
  private dataset = { source: [[]] };
  private series: SeriesOption[] = [];
  private echartOption: EChartsOption;
  private maxPoints: number = ChartResolution.DEFAULT;
  public stdLabel = 'std';
  public meanLabel = 'mean';
  public labelsToShow: Record<string, string>;
  private cycleTimes: Record<string, string>;
  public mergedTrack = false;
  public hasCycles = false;
  public swingTransitions: SwingTransition[];
  public referenceBarPoints: ReplaySubject<number[]> = new ReplaySubject<number[]>();
  public isScaleYToFit = false;
  public hasYLimits = false;
  public disableLeft = false;
  public disableRight = false;
  public isEmgConsistency: boolean = false;
  public initialZoom: InitialZoom = {xZoomStartValue: undefined, xZoomEndValue: undefined, yZoomStartValue: undefined, yZoomEndValue: undefined};
  private lastZoom: InitialZoom = {xZoomStartValue: undefined, xZoomEndValue: undefined, yZoomStartValue: undefined, yZoomEndValue: undefined};
  public storedLimitsY: number[] = [];
  public getMaxPoints(): number {
    return this.maxPoints;
  }
  public enableCycleHighlighting = false;
  public applyChartsOpacityReduction = false;
  public currentHoveredSeries: string;
  private allowConnectNulls: boolean = false;
  public isInsight: boolean = false;

  public setResolution(resolution: ChartResolution): void {
    this.maxPoints = resolution;
  }
  private chartConfig: ChartConfig = this.colorService.getDefaultChartConfig();

  constructor(
    private trialChartsService: TrialChartsService,
    private colorService: ColorService,
    private echartsLegendService: EChartsLegendService,
    private echartsHighlightService: EChartsHighlightService,
    private environmentService: EnvironmentService,
  ) {}

  /**
   * Overall flow of this function:
   * 1. Read options from the DataTrack, configure the overall looks of the chart.
   * 2. Choose whether DataTrack has a time-based or cycle-based X axis.
   * 3. Prepare series for either path.
   * 4. Come back to uniform path, filling in samples.
   */
  public createChart(data: DataTrack): EChartsOption {

    this.dataset = { source: [[]] };
    this.series = [];
    this.referenceBarPoints.next([]);
    const title = data.labels.title;
    const hAxisTitle = data.labels.hAxis;
    const vAxisTitle = data.labels.vAxis;
    const chartColors = data.colors ? data.colors : this.colorService.defaultColorsChart;
    this.echartsLegendService.applyAveragePerCondition = this.trialChartsService.applyAveragePerCondition === true && this.trialChartsService.allowConditionAvgToggle === true;
    this.allowConnectNulls = false;
    if (data?.hasCycles === true) {
      // before filtering, get back the original labels
      const xLabel = data?.labels?.time ?? 'perc';
      data.labels.data = DataHelper.updateLabelsFromSamples(data.values, xLabel);
      data = this.trialChartsService.filterTrackData(data);
      // For EMG cycle data, we can get raw samples in cycles. If we find this:
      // - allow visualizaiton inerpolation (data can have NaNs in cycles
      // - set chart resolution to high to prevent downsampling
      if (data.dataType === 'emg' && data.values.length > 101) {
        this.allowConnectNulls = true;
        this.setResolution(ChartResolution.HIGH);
      }
    }
    this.labelsToShow = data.labels.data;
    this.configureChart(title, hAxisTitle, vAxisTitle, chartColors);

    this.hasYLimits = false;
    if (data.labels.vLimits) {
      this.hasYLimits = true;
      this.setChartVerticalLimits(data.labels.vLimits);
    } else {
      this.isScaleYToFit = true;    // set flag, data will be scaled to fit automatically
    }

    // overwrite limits if we have stored the values
    if (this.storedLimitsY.length > 0) {
      this.setChartVerticalLimits(this.storedLimitsY, true);
    }

    this.chooseTimeOrCyclePath(data);
    if (data?.lineWidths && this.series.length >= data.lineWidths.length) {
      for (let i=0; i<data.lineWidths.length; i++) {
        this.setSeriesLineWidth(i, data.lineWidths[i]);
      }
    }


    if (data?.lineStyles && this.series.length >= data.lineStyles.length) {
      for (let i=0; i<data.lineStyles.length; i++) {
        this.setSeriesLineStyle(i, data.lineStyles[i]);
      }
    }

    if (data.scatterPoints) {
      this.addScatterSeries(data.scatterPoints);
    }

    return this.echartOption;
  }
  private configureChart(title: string, hAxisTitle: string, vAxisTitle: string, chartColors: string[]): void {
    this.echartOption = {
      animation: this.animationsEnabled,
      color: chartColors,
      title: {
        text: title,
        textStyle: {
          color: '#fff',
          overflow: "break",
          lineHeight: 19,
          width: 170,
        },
        padding: 15,
        left: "center"

      },
      legend: {
        textStyle: {
          color: '#fff',
          rich: {
            legendTitle: {
                fontWeight: 'bold',
                align: 'left',
            },
            legendItem: {
              padding: [5, 0, 5, 15]  // [top, right, bottom, left]
            },
          },
        },
        pageIconColor: '#fff',
        pageTextStyle: {
          color: '#fff'
        },
        orient: 'horizontal',
        bottom: 5,
        type: 'scroll',
        itemHeight: 0,
        lineStyle: {
          width: 3
        }
      },
      xAxis: {
        name: hAxisTitle,
        nameTextStyle: {
          color: '#e3e3e3',
        },
        nameGap: 25,
        nameLocation: 'middle',
        type: 'value',
        max: 'dataMax',
        axisLabel: {
          color: '#e3e3e3',
          formatter: (value: number, _index: never) => {
            return (+value.toFixed(3)).toString();
          },
        },
        axisPointer: {
          triggerEmphasis: false,
        },
        splitLine: {
          lineStyle: {
            opacity: 0.3,
          }
        },
        axisLine: {
          lineStyle: {
              color: '#eaeaea',
          }
        },
      },
      yAxis: {
        name: vAxisTitle,
        nameTextStyle: {
          color: '#e3e3e3',
        },
        nameGap: 35,
        nameLocation: 'middle',
        axisLabel: {
          color: '#e3e3e3',
          formatter: (value: number, _index: never) => {
            return (+value.toFixed(3)).toString();
          },
        },
        axisPointer: {
          triggerEmphasis: false,
        },
        splitLine: {
          lineStyle: {
            opacity: 0.3,
          }
        },

      },
      tooltip: {
        trigger: 'axis',
        triggerOn: 'mousemove',
        transitionDuration: 0,
        className: 'echarts-tooltip',
        // enterable: true, // turn this flag on to be able to inspect the tooltip element
        confine: true,
        padding: !this.echartsLegendService.isMultiChartSplitScreen ? 5 : 0,
        textStyle: {
          fontSize: !this.echartsLegendService.isMultiChartSplitScreen ? 12 : 10,
        }
      },
      grid: {
        containLabel: false, // This is necessary to use the grid definitions that 'updatePlayhead' expects, see https://echarts.apache.org/en/option.html#grid.containLabel.
        left: '20%'
      },
      dataZoom: [
        {
          type: 'inside',
          zoomOnMouseWheel: "ctrl",
          minSpan: 1,
          filterMode: "none",
          xAxisIndex: [0],
          startValue: this.initialZoom.xZoomStartValue,
          endValue: this.initialZoom.xZoomEndValue
        },{
          type: 'inside',
          zoomOnMouseWheel: "alt",
          minSpan: 2,
          filterMode: 'none',
          yAxisIndex: [0],
          startValue: this.initialZoom.yZoomStartValue,
          endValue: this.initialZoom.yZoomEndValue
        },
      ],
    };
    this.addFirstColumn();
  }

  public convertTimeToCycle(playbackTime: number, dataContext?: DataContext, trackFilter?: string): Record<number, string> {
    let perc = -1;
    let cycleLabel;
    if (this.cycleTimes) {
      const curCycle = DataHelper.getCyclePercentageFromTime(playbackTime, this.cycleTimes, dataContext, trackFilter);
      perc = curCycle['cyclePerc'];
      if (perc >= 0) {
        cycleLabel = curCycle['cycleLabel'];
      }
    }
    const curCycle = {perc: perc, label: cycleLabel};
    return curCycle;
  }

  private chooseTimeOrCyclePath(data: DataTrack): void {
    if (data.mergedTrack) {
      this.mergedTrack = data.mergedTrack;
    }
    const trackHasCycles = data.hasCycles ? data.hasCycles : false;
    this.swingTransitions = data?.swingTransitions ?? undefined;
    if (data.events) {
      const context = DataHelper.getDataContext(data.labels.title, DataContext.leftSide, data.mergedTrack);
      let cycles;
      let cyclesLeft;
      if (context === DataContext.bothSides) {
        cyclesLeft = DataHelper.getCyclesForContext(data.events, DataContext.leftSide);
        cycles = DataHelper.getCyclesForContext(data.events, DataContext.rightSide);
        this.cycleTimes = DataHelper.getCycleTimes(cycles, cyclesLeft);
      } else {
        cycles = DataHelper.getCyclesForContext(data.events, context);
        this.cycleTimes = DataHelper.getCycleTimes(cycles);
      }

      if (cycles.length > 0) {
        this.hasCycles = true;
        data.labels.time = 'perc';    // explicitly overwrite label to 'perc'
        this.readCyclesValues(data, cycles, cyclesLeft);
        this.updateTooltip(data, true, this.trialChartsService.simplifyLegendLabelsForReport, this.trialChartsService.showAveragesForCycles);
      } else {
        this.hasCycles = trackHasCycles;
        this.readTimeValues(data);
        this.updateTooltip(data, false,this.trialChartsService.simplifyLegendLabelsForReport, this.trialChartsService.showAveragesForCycles);
      }
    } else {
      this.hasCycles = trackHasCycles;
      this.readTimeValues(data);
      if (data.labels.hAxis.toLowerCase().includes('cycle')) {
        this.hasCycles = true;
        this.cycleTimes = data['cycleTimes'];
        if (data['cycleTimes']) {
          this.cycleTimes = data['cycleTimes'];
        }
      }
      this.updateTooltip(data, false, this.trialChartsService.simplifyLegendLabelsForReport, this.trialChartsService.showAveragesForCycles);
    }
    if (this.hasCycles) {
      this.setXAxisForGaitCycle();
    }

    if (data.labels.time === 'days') {
      this.setXAxisForDays(data.values, data?.scatterPoints);
    }

    this.echartsLegendService.updateLegend(this.echartOption, this.trialChartsService.mergeLegendLabelsForCycles, this.trialChartsService.simplifyLegendLabelsForReport, this.hasCycles, this.meanLabel, this.trialChartsService.showAveragesForCycles, this.isEmgConsistency, this.getChartMode());
  }

  private readCyclesValues(data: DataTrack, cycles: Cycle[], cyclesLeft?: Cycle[]): void {
    const values = data.values;
    const hideIndividualCycles = this.trialChartsService.showAveragesForCycles;
    const context = DataHelper.getDataContext(data.labels.title, DataContext.noSide, data.mergedTrack);
    const updateLineStyles = this.prepareCyclesColumns(context, cycles, cyclesLeft, hideIndividualCycles);
    let samples: Sample[];
    if (data.mergedTrack) {
      const samplesRight: Sample[] = DataHelper.addDataToCycles(values, cycles, data.labels.data, 'right');
      const samplesLeft: Sample[] = DataHelper.addDataToCycles(values, cyclesLeft, data.labels.data, 'left');
      samples = DataHelper.mergeDataCycles(samplesLeft, samplesRight);
    } else {
      samples = DataHelper.addDataToCycles(values, cycles, data.labels.data);
    }

    const hasStd = true;
    const skipFactor = 1;
   this.fillChartData(samples, hasStd, updateLineStyles, data.labels.time, skipFactor, context, hideIndividualCycles, data?.hasGcdCycles === true);
  }

  /**
   * this function populates an array with start and end values, used to draw the EMG reference bars
   */
  private extractReferenceBars(referenceValues: ReferenceCoordinate[]): number[] {
    const pointsArray: number[] = [];
    for (let i = 0; i< referenceValues.length; i++) {
      if (referenceValues[i].mean === 1) {
        if (i == 0 || referenceValues[i-1].mean === 0) {
          pointsArray.push(referenceValues[i].perc);
        } else if (i+1 === referenceValues.length) {
          pointsArray.push(referenceValues[i].perc);
        }
      }
      if (i !== 0 && referenceValues[i].mean === 0 && referenceValues[i-1].mean === 1) {
        pointsArray.push(referenceValues[i-1].perc);
      }
    }
    return pointsArray;
  }

  private setXAxisForGaitCycle() {
    this.echartOption.xAxis['min'] = 0;
    this.echartOption.xAxis['max'] = 100;
  }

  private setXAxisForDays(values: Sample[], scatterPoints: ScatterPointValues): void {
    let vMin = Math.min(0, ...values.map(v => v.days));
    let vMax = Math.max(0, ...values.map(v => v.days));

    if (scatterPoints && scatterPoints.values && scatterPoints.values.length > 0) {
      vMin = Math.min(vMin,...scatterPoints.values.map(x => x[0]));
      vMax = Math.max(vMax,...scatterPoints.values.map(x => x[0]));

    }
    if (vMin && !isNaN(vMin)) {
      this.echartOption.xAxis['min'] = vMin;
    }
    if (vMax && !isNaN(vMax)) {
      this.echartOption.xAxis['max'] = vMax;
    }
  }

  private readTimeValues(data: DataTrack): void {
    const context = DataHelper.getDataContext(data.labels.title, DataContext.noSide, data.mergedTrack);
    let hasStd = false;
    for (const p in this.labelsToShow) {
      if (p.toLowerCase().substring(0,this.stdLabel.length) == this.stdLabel.toLowerCase()) {
        hasStd = true;
        break;
      }
    }
    const skipFactor = data.skip ? data.skip : 1;
    const timeLabel = data.labels.time;
    const updateLineStyles = this.prepareTimeColumns(context, data.id, data.dataType);
    const localValues: Sample[] = this.trialChartsService.filterValues(data.values, timeLabel, this.labelsToShow);
    if (this.hasCycles && data.referenceBars) {
      const pointsArray = this.extractReferenceBars(data.referenceBars.values);
      this.referenceBarPoints.next(pointsArray);
    }
    this.fillChartData(localValues, hasStd, updateLineStyles, data.labels.time, skipFactor, context, false, data?.hasGcdCycles === true);
  }

  private prepareCyclesColumns(context: string, cycles: Cycle[], cyclesLeft: Cycle[], hideIndividualCycles: boolean): boolean {
    const updateLineStyles = false;
    const colors = [];
    let colorCount = Object.keys(this.labelsToShow).length;

    let cols = [this.colorService.right, this.colorService.cyan, this.colorService.blue];
    let colsAvg = [this.colorService.gray, this.colorService.gray, this.colorService.gray];

    if (colorCount === 1) {
      cols = [this.colorService.gray];
      colsAvg = [this.getContextColor(context)];
    }

    if (context === DataContext.bothSides) {
      cols = [this.colorService.gray, this.colorService.gray];
      colsAvg = [this.getContextColor(DataContext.leftSide), this.getContextColor(DataContext.rightSide)];
    }

    // First push avg column, then rest
    colorCount = 0;
    for (const p in this.labelsToShow) {
      this.pushColumn(this.labelsToShow[p]);
      colors.push(colsAvg[colorCount++]); //avg-x,y,z
    }

    if (!hideIndividualCycles) {
      let nCycles;
      let nCyclesAdd;
      if (context === DataContext.bothSides) {
        nCycles = Math.min(cycles.length, cyclesLeft.length);
        nCyclesAdd = Math.max(cycles.length, cyclesLeft.length) - nCycles;
      } else {
        nCycles = cycles.length;
      }

      for (let i = 0; i < nCycles; i++) {
        colorCount = 0;
        for (const p in this.labelsToShow) {
          colors.push(cols[colorCount++]);
          this.pushColumn(this.labelsToShow[p] + '-' + i, -1);  // Make sure to get the cycle lines behind the others
        }
      }

      if (context === DataContext.bothSides && nCyclesAdd > 0) {
        for (let i = 0; i < nCyclesAdd; i++) {
          const j = i + nCycles;
          colorCount = 0;
          for (const p in this.labelsToShow) {
            const labelToCheck = p.split(' > ')[0];   // Cut everything after ' > ' (keep original string if ' > ' not found)
            if (labelToCheck.includes('left') && cyclesLeft.length > j) {
              colors.push(cols[colorCount++]);
              this.pushColumn(this.labelsToShow[p] + '-' + j, -1);
            } else if (labelToCheck.includes('right') && cycles.length > j) {
              colors.push(cols[colorCount++]);
              this.pushColumn(this.labelsToShow[p] + '-' + j, -1);
            } else {
              colorCount++;
            }
          }
        }
      }
    }
    this.setChartColors(colors);
    this.setSeriesLineWidth(0,2);
    if (context === DataContext.bothSides ) {
      this.setSeriesLineWidth(1,2);
    }

    return updateLineStyles;
  }

  private prepareTimeColumns(context: string, id: string, chartDataType: ChartDataTypes): boolean {
    const idLowerCase = id ? id.toLowerCase() : '';
    const isEmgWithoutCycles = this.hasCycles === false && chartDataType === 'emg' && (idLowerCase.includes('emg-input#') || idLowerCase.includes('analog-input#'));
    this.isEmgConsistency = idLowerCase.includes('emg consistency - '); // todo, convert to chart type
    let hasEmgProcessed = false;
    const chartsToSkipForContextColors = (isEmgWithoutCycles || (chartDataType === 'force' && (idLowerCase.includes('force-input#') || idLowerCase.includes('force_plate')) && this.hasCycles !== true) || idLowerCase.includes('progression_params') || chartDataType === 'csv')
    let updateColors = !chartsToSkipForContextColors;
    let updateLineStyles = false;
    if (!this.trialChartsService.showLineStyles) {
      updateLineStyles = updateColors;
    }
    const myLabelKeys = Object.keys(this.labelsToShow);
    let colors = [];
    if (!updateColors) {
      // make sure we have enough colors available
      const nLines = myLabelKeys.length;
      for (let i=0; i < 10; i++) {
        colors.push(...this.colorService.defaultColorsChart);
        if (colors.length >= nLines) {
          break;
        }
      }
    }


    if (chartDataType === 'emg' && myLabelKeys.findIndex(v => v.split(' > ')[0].includes("rms")) !== -1 || myLabelKeys.findIndex(v => v.split(' > ')[0].includes("processed")) !== -1 ) {
      hasEmgProcessed = true;
      updateLineStyles = this.getChartMode() !== 'trial';
    }

    // first set all entries to false if we don't have EMG consistency
    if (!this.isEmgConsistency) {
      Object.keys(this.echartsLegendService.lineSelectionByLegend).forEach((key:string) => {
        this.echartsLegendService.lineSelectionByLegend[key] = false;
      });
    }

    for (const p in this.labelsToShow) {
      if (p.toLowerCase().substring(0,this.stdLabel.length) === this.stdLabel.toLowerCase()) {
        continue;
      }
      let skipColumn = false;
      if (isEmgWithoutCycles && this.labelsToShow[p] === undefined) {
        skipColumn = true;
      }
      const color = this.getLineColor(context, p, hasEmgProcessed);
      if (!this.isEmgConsistency && !chartsToSkipForContextColors) {
        this.echartsLegendService.lineSelectionByLegend[p] = this.showLineBasedOnContext(context, p);
      }
      if (!skipColumn) {
        colors.push(color);
        const label = this.labelsToShow[p];
        // specify an higher z-index for the RMS lines
        if (chartDataType === 'emg' && label.split(' > ')[0].includes("rms") || label.split(' > ')[0].includes("processed")) {
          this.pushColumn(label, 100);
        } else {
          this.pushColumn(label);
        }
      }
    }
    if (isEmgWithoutCycles) {
      // this is emg for mox, others are handled in data parser...
      colors = [];
      let nLabels = 0;
      for (let label in this.labelsToShow) {
        label = label.replace(' (left)', '');
        label = label.replace(' (right)', '');
        if (label === 'value') {
          colors.push(this.colorService.orange);
        } else if (label === 'rms' || label === 'processed') {
          colors.push(this.colorService.right);
        }
        nLabels++;
      }
      if (colors.length > 0 && colors.length === nLabels) {
        updateColors = true;
      }

    }

    if (this.isEmgConsistency) {
      // this is our custom emg consistency chart, add colors from default list
      colors = [];
      const updateLineStyleToDashed = false;
      const lineStyleIdsToUpdate: number[] = [];
      const channelNames: string[] = [];
      const colorIndices: number[] = [];
      let iLabel = 0;
      // we loop through the labels and keep track of the channelnames and assign the right color and optionally linestyle
      for (const label in this.labelsToShow) {
        const splitLabels = label.split(' > ');
        if (splitLabels.length > 0 && !channelNames.includes(splitLabels[1])) {
          channelNames.push(splitLabels[1]);
          colorIndices.push(Math.min(iLabel, this.colorService.defaultColorsConsistency.length-1));
          iLabel++;
        }

        let colorIndex = 0;
        if (splitLabels.length > 0) {
          colorIndex = colorIndices[channelNames.indexOf(splitLabels[1])];
        } else {
          colorIndex = Math.min(iLabel, this.colorService.defaultColorsConsistency.length-1);
          iLabel++;
        }

        // keep track of rms/processed tracks to potentially update linestyles (see updateLineStyleToDashed)
        const labelToCheck = splitLabels.length > 0 ? splitLabels[0] : label;
        if (labelToCheck.includes("rms") || labelToCheck.includes("processed")) {
          // set line style of series to dashed (keep track of series number)
          let iSeriesNumber = 0;
          for (const iSeries in this.echartOption.series) {
            if (this.echartOption.series[iSeries]?.lineStyle && this.echartOption.series[iSeries].name === label) {
              lineStyleIdsToUpdate.push(iSeriesNumber);
              break;
            }
            iSeriesNumber++;
          }
        }

        // Note: with issue 2215, decided to switch off the different linestyles, but keeping the code alive.
        // // only update linestyle if we find a value + rms/processed
        // if (!updateLineStyleToDashed && labelToCheck.includes("value")) {
        //   updateLineStyleToDashed = true;
        // }

        colors.push(this.colorService.defaultColorsConsistency[colorIndex]);
      }
      if (colors.length > 0) {
        updateColors = true;
      }
      if (updateLineStyleToDashed) {
        for (const linestyleToUpdate of lineStyleIdsToUpdate) {
          this.setSeriesLineStyle(linestyleToUpdate, 1);
        }
      }
    }

    if (updateColors) {
      this.setChartColors(colors);
    }

    return updateLineStyles;
  }

  private fillChartData(data: Sample[], hasStd: boolean, updateLineStyles: boolean, timeLabel: string, skipFactor: number, context: string, hideIndividualCycles: boolean, hasGcdCycles: boolean): void {

    const decimate = Math.ceil(data.length / this.maxPoints);
    let skip = skipFactor;
    if (!isNaN(decimate) && decimate > skip) {
      skip = decimate;
    }

    const skippedData: Sample[] = [];
    for (let i = 0; i < data.length; i = i + skip) {
      skippedData.push(data[i]);
    }

    this.addSamples(skippedData, timeLabel, hideIndividualCycles);

    if (hasStd) {
      const meanLabels = [];
      const meanLabelsToShow = [];
      const stdLabels = [];
      let iMean = 0;
      const labelsToShow = Object.keys(this.labelsToShow);
      let hasEmgProcessed = false;
      if (labelsToShow.findIndex(v => v.includes("rms")) !== -1 || labelsToShow.findIndex(v => v.includes("processed")) !== -1 ) {
        hasEmgProcessed = true;
      }

      for (const p in skippedData[0]) {
        if (p.toLowerCase().substring(0,this.meanLabel.length) === this.meanLabel.toLowerCase()) {
          meanLabels.push(p);
          let meanLabelToShow = p;
          let labelFound = false;
          for (const l in this.labelsToShow) {
            if (l === p) {
              labelFound = true;
              break;
            }
          }
          if (!labelFound) {
            meanLabelToShow = labelsToShow[Math.min(iMean,labelsToShow.length-1)];  // fallback to labelsToShow
          }

          meanLabelsToShow.push(meanLabelToShow);
          iMean++;
        } else if (p.toLowerCase().substring(0,this.stdLabel.length) === this.stdLabel.toLowerCase()) {
          stdLabels.push(p);
        }
      }
      if (meanLabels.length === stdLabels.length) {
        for (let iPolygon = 0; iPolygon < meanLabels.length; iPolygon++) {
          const dataPolygon = this.samplesToPolygon(skippedData, timeLabel, meanLabels[iPolygon], stdLabels[iPolygon]);
          this.addPolygon(dataPolygon, context, meanLabelsToShow[iPolygon], hasEmgProcessed);
        }
      }
    }

    if (updateLineStyles) {
      this.setChartSeries(context, hasGcdCycles);
    }
  }

  public setSeriesLineWidth(seriesId: number, lineWidth: number): void {
    this.echartOption.series[seriesId].lineStyle.width = lineWidth;
  }

  public setSeriesLineStyle(seriesId: number, iLineStyle: number): void {
    if (this.echartOption.series[seriesId]?.lineStyle) {
      this.echartOption.series[seriesId].lineStyle.type = this.trialChartsService.defaultLineDashStyles[iLineStyle];
    }
  }

  public setChartVerticalLimits(limits: number[], forceLimits: boolean = false): void {
    this.echartOption.yAxis['min'] = this.isScaleYToFit && !forceLimits ? 'dataMin' : this.addDecimalDigit(limits[0]);
    this.echartOption.yAxis['max'] = this.isScaleYToFit && !forceLimits ? 'dataMax' : this.addDecimalDigit(limits[1]);
  }

  private addDecimalDigit(number: number): number {
    let strNumber = number.toString();
    // if the number does not contain a decimal point, add ".0"
    if (!strNumber.includes('.')) {
      return Math.round(number*10)/10;
    }
    // if it has decimals, add one zero
    const pow_10 = Math.pow(10, strNumber.split('.')[1].length + 1);
    return Math.round(number*pow_10)/pow_10;
  }

  public setChartColors(colors: string[]): void {
    this.echartOption.color = colors;
  }

  private addFirstColumn(): void {
    this.addColumn("time");
  }

  private addColumn(label: string) {
    this.dataset.source[0].push(label);
    this.echartOption.dataset = this.dataset;
  }

  private pushColumn(label: string, z?: number): void {
    this.addColumn(label);
    const isReference = label.toLowerCase().includes('<<<reference>>>');
    const inactiveLineWidth = this.isInsight ? Number(this.chartConfig.inactiveCycleWidth) : !isReference ? this.inactiveLineWidth() : 0.75;
    const newSeries: SeriesOption = {
      // enables the event emitter for "chartClick"
      triggerLineEvent: true,
      type: 'line',
      encode: {
        x: 'time',
        y: label
      },
      lineStyle: {
        width: inactiveLineWidth,
        opacity: this.applyChartsOpacityReduction && this.enableCycleHighlighting && !isReference ? 0.5 : 1,
      },
      emphasis: {
        // Highlight style
        lineStyle: {
          width: this.isInsight ? Number(this.chartConfig.activeCycleWidth) : 2.75,
          opacity: 1,
        },
      },
      name: label,
      symbol: 'none',
      connectNulls: this.allowConnectNulls
    };
    if (z) {
      newSeries.z = z;
    }
    this.series.push(newSeries);
    this.echartOption.series = this.series;
  }

  // returns the width to use for the inactive gait cycle, based on the number of lines in the chart
  public inactiveLineWidth(): number {
    // we use the number of lines in the chart to determine the opacity and width to use
    const tooMuchLines = this.getChartMode() === 'report' && this.trialChartsService.trialNamesReport.length > 0 ? Math.round(Object.keys(this.labelsToShow).length / this.trialChartsService.trialNamesReport.length) > 20 : Object.keys(this.labelsToShow).length > 20;
    const allowIncreasedLineWidth = this.echartsLegendService.isFullscreen || this.echartsLegendService.splitView || this.echartsLegendService.isMultiChartSplitScreen;
    return allowIncreasedLineWidth && !tooMuchLines ? 1.75 : 0.75;
  }


  private samplesToPolygon(data: Sample[], timeLabel: string, meanLabel: string, stdLabel: string): PolygonPoint[] {
    const dataPlusStd = [];
    const dataMinStd = [];
    for (let i = 0; i < data.length; i = i + 1) {
      let xData = 0;
      let yMean = 0;
      let yStd = 0;
      for (const p in data[i]) {
        if (p.toLowerCase() === timeLabel) {
          xData = data[i][p];
        } else if (p.toLowerCase().substring(0,meanLabel.length) === meanLabel.toLowerCase()) {
          yMean = data[i][p];
        } else if (p.toLowerCase().substring(0,stdLabel.length) === stdLabel.toLowerCase()) {
          yStd = data[i][p];
        }
      }
      dataPlusStd.push([xData, yMean + yStd]);
      dataMinStd.push([xData, yMean - yStd]);

    }
    const dataPolygon = [...dataPlusStd, ...dataMinStd.reverse()];
    return dataPolygon;
  }

  private getLineColor(context: string, label: string, hasEmgProcessed?: boolean) {
    const applyEmgColoring = hasEmgProcessed !== undefined ? hasEmgProcessed : false;
    let color;
    const labelToCheckWithoutTrialName = label.split(' > ')[0];   // Cut everything after ' > ' (keep original string if ' > ' not found)
    if (label.toLowerCase().includes('<<<reference>>>')) {
      color = this.isInsight ? this.chartConfig.referenceColor : this.colorService.referenceColor;
    } else if (applyEmgColoring && !(labelToCheckWithoutTrialName.includes("rms") || labelToCheckWithoutTrialName.includes("processed"))) {
      color = this.colorService.gray;
    } else {
      const curContext = DataHelper.splitDataContextFromLabel(context, labelToCheckWithoutTrialName);
      color = this.getContextColor(curContext);
    }
    return color;
  }


  private showLineBasedOnContext(context: string, label: string) {
    const labelToCheckWithoutTrialName = label.split(' > ')[0];   // Cut everything after ' > ' (keep original string if ' > ' not found)
    if (!label.toLowerCase().includes('<<<reference>>>')) {
      const curContext = DataHelper.splitDataContextFromLabel(context, labelToCheckWithoutTrialName);
      if (curContext == DataContext.leftSide && this.disableLeft) {
        return false;
      } else if (curContext == DataContext.rightSide && this.disableRight) {
        return false;
      }
    }
    return true;
  }

  private addPolygon(polygonPoints: PolygonPoint[], context: string, label: string, hasEmgProcessed?: boolean): void {
    const color = this.getLineColor(context, label, hasEmgProcessed);
    if (!this.isEmgConsistency) {
      this.echartsLegendService.lineSelectionByLegend[label] = this.showLineBasedOnContext(context, label);
    }

    const newSeries: SeriesOption = {
      type: 'custom',
      name: label,
      renderItem: function (params, api) {
        if (params.context.rendered) {
          return;
        }
        params.context.rendered = true;
        const points = [];
        for (let i = 0; i < polygonPoints.length; i++) {
          points.push(api.coord(polygonPoints[i]));
        }
        return {
          type: 'polygon',
          transition: ['shape'],
          shape: {
            points: points,
          },
          style: {
            fill: color,
            //stroke: echarts.color.lift(color, 0.1),
            opacity: 0.2
          },
        };
      },
      clip: true,
      data: polygonPoints,
      tooltip: {
        show: false
      }
    };
    this.series.push(newSeries);
    this.echartOption.series = this.series;
  }

  private addSamples(data: Sample[], timeLabel: string, hideIndividualCycles: boolean): void {
    for (let i = 0; i < data.length; i = i + 1) {
      let count = 0;
      const samples: number[] = [];
      samples[0] = data[i][timeLabel];
      count++;


      for (const p in data[i]) {
        let skipSample = false;
        if (this.hasCycles) {
          // only push samples with matching (part of) label
          skipSample = true;
          if (!hideIndividualCycles) {
            for (const label in this.labelsToShow) {
              if (p.toLowerCase().substring(0, label.length) === label.toLowerCase() && label.includes(this.stdLabel) === false) {
                skipSample = false;
                break;
              }
            }
          }
        } else {
          if (p.toLowerCase() === timeLabel || p.toLowerCase().substring(0, this.stdLabel.length) === this.stdLabel.toLowerCase()) {
            // Skip time sample and std sample
            skipSample = true;
          }
        }

        if (!skipSample) {
          samples[count++] = data[i][p];
        }
      }

      this.pushRow(samples);
    }
  }

  private pushRow(dataSampleAsArray: number[]): void {
    this.dataset.source.push(dataSampleAsArray);
    this.echartOption.dataset = this.dataset;
  }

  private setChartSeries(context: string, hasGcdCycles: boolean): void {
    let iLineStyle = 0;
    let iLineStyleLeft = 0;
    let iLineStyleRight = 0;

    // only update linestyles for new trials in track (for comparison)
    const trialNames = [];
    const trialNamesLeft = [];
    const trialNamesRight = [];

    // first check whether we have multiple conditions in report
    let multiConditionReport = false;
    let conditionAndSession: string;
    for (const iSeries in this.echartOption.series) {
      if (this.echartOption.series[iSeries]?.lineStyle) {
        const index = this.echartOption.series[iSeries].name.indexOf(' > ');
        let trialName = '';
        if (index > -1 ) {
          trialName = this.echartOption.series[iSeries].name.substring(index);
        }
        if (trialName.toLowerCase().includes('<<<reference>>>')) {
          continue;
        }
        if (this.getChartMode() === 'report' && trialName.split(' > ').length > 2) {
          const sessionName = trialName.split(' > ')[1];
          const conditionName = trialName.split(' > ')[2];

          if (conditionAndSession === undefined) {
            conditionAndSession = sessionName + ' > ' + conditionName;
          } else if (conditionAndSession !==  sessionName + ' > ' + conditionName) {
            multiConditionReport = true;
            break;
          }
        }
      }
    }

    const updateLineStylesWithinTrial = hasGcdCycles === true;

    const sessionAndConditionsMap = new Map<string, string[]>();
    for (const iSeries in this.echartOption.series) {
        if (this.echartOption.series[iSeries]?.lineStyle) {
          const index = this.echartOption.series[iSeries].name.indexOf(' > ');
          let trialName = '';
          let increaseCounter = true;
          if (index > -1 ) {
            trialName = this.echartOption.series[iSeries].name.substring(index);
          }
          if (trialName.toLowerCase().includes('<<<reference>>>')) {
            continue;
          }
          if (this.getChartMode() === 'report' && multiConditionReport && trialName.split(' > ').length > 2) {
            const sessionName = trialName.split(' > ')[1];
            const conditionName = trialName.split(' > ')[2];
            if (!sessionAndConditionsMap.has(sessionName)) {
              if (sessionAndConditionsMap.size > 0) {
                iLineStyle++;
              }
              sessionAndConditionsMap.set(sessionName, [conditionName]);
            } else if (sessionAndConditionsMap.get(sessionName).indexOf(conditionName) === -1) {
              iLineStyle++;
              sessionAndConditionsMap.get(sessionName).push(conditionName);
            }
            this.echartOption.series[iSeries].lineStyle.type = this.trialChartsService.defaultLineDashStyles[iLineStyle];
          } else {
            const curContext = DataHelper.splitDataContextFromLabel(context,this.echartOption.series[iSeries].name);
            if (context === DataContext.bothSides && curContext === DataContext.leftSide) {
              increaseCounter = updateLineStylesWithinTrial;
              if (trialName.length > 0) {
                if (trialNamesLeft.indexOf(trialName) === -1) {
                  if (trialNamesLeft.length > 0 && !increaseCounter) {
                    iLineStyleLeft++;
                  }
                  trialNamesLeft.push(trialName);
                }
              }
              this.echartOption.series[iSeries].lineStyle.type = this.trialChartsService.defaultLineDashStyles[iLineStyleLeft];
              if (increaseCounter) {
                iLineStyleLeft++;
              }
            } else if (context === DataContext.bothSides && curContext === DataContext.rightSide) {
              increaseCounter = updateLineStylesWithinTrial;
              if (trialName.length > 0) {
                if (trialNamesRight.indexOf(trialName) === -1) {
                  if (trialNamesRight.length > 0 && !increaseCounter) {
                    iLineStyleRight++;
                  }
                  trialNamesRight.push(trialName);
                }
              }
              this.echartOption.series[iSeries].lineStyle.type = this.trialChartsService.defaultLineDashStyles[iLineStyleRight];
              if (increaseCounter) {
                iLineStyleRight++;
              }
            } else {
              increaseCounter = updateLineStylesWithinTrial;
              if (trialName.length > 0) {
                if (trialNames.indexOf(trialName) === -1) {
                  if (trialNames.length > 0 && !increaseCounter) {
                    iLineStyle++;
                  }
                  trialNames.push(trialName);
                }
              }
              this.echartOption.series[iSeries].lineStyle.type = this.trialChartsService.defaultLineDashStyles[iLineStyle];
              if (increaseCounter) {
                iLineStyle++;
              }
            }
          }
        }
    }
  }

  private updateTooltip(data: DataTrack, cycles: boolean, simplifyLegendLabelsForReport: boolean, showAveragesForCycles: boolean): void {
    let nAvg = 1;
    if (data.mergedTrack) {
      nAvg = 2;
    }
    this.echartOption['tooltip']['formatter']  = (params) => {
      let res = `<b>${data.labels.title}</b> - ${data.labels.vAxis} <br>`;
      res += data.labels.hAxis + ': ' + params[0].data[0].toFixed(2) + "<br>";
      let showTooltip = false;
      let nameStr;
      if (cycles) {
        for (let iCycleAvg = 0; iCycleAvg < nAvg; iCycleAvg ++) {
          if (this.currentHoveredSeries && this.currentHoveredSeries.includes(params[iCycleAvg].seriesName)) {
            showTooltip = true;
            nameStr = params[iCycleAvg].seriesName;
            if (nameStr.includes(this.meanLabel)) {
              const index = nameStr.indexOf("- ");
              if (index !== -1) {
                nameStr = nameStr.substring(index + 2);
              }
            }
            // for reports, put value on new line
            if (this.getChartMode() === 'report') {
              res += '<br>' + params[iCycleAvg].marker + nameStr + '<br>&nbsp&nbsp&nbsp&nbspvalue: ' + params[iCycleAvg].data[iCycleAvg+1].toFixed(3);
            } else {
              res += '<br>' + params[iCycleAvg].marker + nameStr + ': ' +(params[iCycleAvg].data[iCycleAvg+1].toFixed(3));
            }
          }
        }
      } else {
        for (let iParam = 0; iParam < params.length; iParam++) {
          if (this.currentHoveredSeries && this.currentHoveredSeries.includes(params[iParam].seriesName)) {
            showTooltip = true;
            let tooltipValue;
            const dataIndex = params[iParam].seriesIndex ? params[iParam].seriesIndex + 1 : iParam + 1;
            if (params[iParam].data[dataIndex]) {
              tooltipValue = params[iParam].data[dataIndex].toFixed(3);
            } else {
              tooltipValue = 'No data available';
            }
            nameStr = params[iParam].seriesName;
            if (simplifyLegendLabelsForReport && this.getChartMode() === 'report') {
              const mergeLegendLabelsForCycles = false;
              nameStr = this.echartsLegendService.updateSerieStringForReport(nameStr, this.meanLabel, showAveragesForCycles, this.hasCycles, mergeLegendLabelsForCycles, true);
            } else if (this.getChartMode() === 'trial') {
              nameStr = this.echartsLegendService.increaseCycleCountLabelForReport(nameStr, false, false);
              nameStr = this.echartsLegendService.replaceNameForInsightForces(nameStr);
              nameStr = this.echartsLegendService.replaceInsightTrialPaths(nameStr);
              nameStr = this.echartsLegendService.replaceNameForReferenceAndAverage(nameStr);
            }
            // for reports, put value on new line
            if (this.getChartMode() === 'report') {
              res += '<br>' + params[iParam].marker + nameStr + '<br>&nbsp&nbsp&nbsp&nbspvalue: ' + tooltipValue;
            } else {
              res += '<br>' + params[iParam].marker + nameStr + ': ' + tooltipValue;
            }
          }
        }
      }
      if (!showTooltip) {
        res = '';
      }
      return res;
    };
  }

  private getContextColor(context: string) {
    let color;
    if (context == DataContext.rightSide) {
      color = this.isInsight ? this.chartConfig.rightColor : this.colorService.right;
    } else if (context == DataContext.leftSide) {
      color = this.isInsight ? this.chartConfig.leftColor : this.colorService.left;
    } else {
      color = this.isInsight ? this.chartConfig.neutralColor : this.colorService.blue;
    }
    return color;
  }

  private addScatterSeries(scatterPoints: ScatterPointValues): void {
    const newSeries: SeriesOption = {
      type: 'scatter',
      name: scatterPoints.label,
      data: scatterPoints.values,
      tooltip: {
        show: false
      },
      color: scatterPoints.color
    };
    // make sure we get the "ref" and "peer" after the scatterpoints
    const curSeries = JSON.parse(JSON.stringify(this.series));
    this.series = [];
    for (const serie of curSeries) {
      if (!(serie.name.includes('ref-') || serie.name.includes('peer'))) {
        this.series.push(serie);
      }
    }
    this.series.push(newSeries);
    for (const serie of curSeries) {
      if (serie.name.includes('ref-') || serie.name.includes('peer')) {
        this.series.push(serie);
      }
    }
    this.echartOption.series = this.series;
  }

  public getChartMode(): 'trial' | 'report' {
    return this.trialChartsService.getChartMode();
  }

  public resetInitialZoom(): void {
    this.initialZoom.xZoomStartValue = undefined;
    this.initialZoom.xZoomEndValue = undefined;
    this.initialZoom.yZoomStartValue = undefined;
    this.initialZoom.yZoomEndValue = undefined;
  }

  public applyDataZoom(dataZoom: DataZoomComponentOption, applyForX: boolean = true, applyForY: boolean = true, useLastZoom: boolean = false): void {
    this.resetInitialZoom();
    this.storedLimitsY = [];
    if (dataZoom) {
      for (const dataZoomAxis in dataZoom) {
        if (applyForX && dataZoom[dataZoomAxis].xAxisIndex) {
          this.initialZoom.xZoomStartValue = !isNaN(dataZoom[dataZoomAxis].startValue) ? dataZoom[dataZoomAxis].startValue : this.lastZoom.xZoomStartValue;
          this.initialZoom.xZoomEndValue = !isNaN(dataZoom[dataZoomAxis].endValue) ? dataZoom[dataZoomAxis].endValue : this.lastZoom.xZoomEndValue;
        }
        if (applyForY && dataZoom[dataZoomAxis].yAxisIndex) {
          const startValue = this.lastZoom[dataZoomAxis] && useLastZoom ? Math.min(this.lastZoom.yZoomStartValue, dataZoom[dataZoomAxis].startValue) : dataZoom[dataZoomAxis].startValue;
          const endValue = this.lastZoom[dataZoomAxis] && useLastZoom ? Math.max(this.lastZoom.yZoomEndValue, dataZoom[dataZoomAxis].endValue) : dataZoom[dataZoomAxis].endValue;
          this.initialZoom.yZoomStartValue = this.lastZoom[dataZoomAxis] && useLastZoom ? Math.min(this.lastZoom.yZoomStartValue, dataZoom[dataZoomAxis].startValue) : dataZoom[dataZoomAxis].startValue;
          this.initialZoom.yZoomEndValue = this.lastZoom[dataZoomAxis] && useLastZoom ? Math.max(this.lastZoom.yZoomEndValue, dataZoom[dataZoomAxis].endValue) : dataZoom[dataZoomAxis].endValue;
          if (useLastZoom) {
            this.storedLimitsY = [startValue, endValue];
          }
        }
      }
    }
    if (useLastZoom) {
      this.lastZoom = JSON.parse(JSON.stringify(this.initialZoom));
    }
  }

  public isSwingTransitionEnabled(swingTransition: SwingTransition): boolean {
    if (this.getChartMode() === 'report') {
      // if we have legends disabled, find corresponding cycles (which can be multiple if we have mean)
      for (const selectedLine in this.echartsLegendService.lineSelectionByLegend) {
        const swingTransitionFound = this.echartsHighlightService.findCycleMatchForReport(selectedLine, swingTransition, undefined, undefined, this.trialChartsService.showAveragesForCycles, this.trialChartsService.applyAveragePerCondition);
        if (swingTransitionFound) {
          return this.echartsLegendService.lineSelectionByLegend[selectedLine];
        }
      }
    } else {
      if (this.echartsLegendService.lineSelectionByLegend === undefined) {
        return true;
      } else {
        return this.echartsLegendService.lineSelectionByLegend[swingTransition.cycle];
      }
    }
    return false;
  }

  public getSwingTransitionLineStyle(swingTransition: SwingTransition): number[] {
    for (const serie in this.echartOption.series) {
      if (this.echartOption.series[serie]['type'] === 'line') {
        if (this.getChartMode() === 'report') {
          const name = this.echartOption.series[serie]['name'];
          const trialNameSplit = swingTransition.trialName.split(' > ');
          const nameToCheck = name.endsWith('<<<conditionAvg>>>') && trialNameSplit.length > 2 ? trialNameSplit[0] + ' > ' + trialNameSplit [1] + ' > <<<conditionAvg>>>' : swingTransition.trialName;
          if ((name.startsWith(swingTransition.cycle) || this.trialChartsService.showAveragesForCycles) && name.endsWith(nameToCheck) && this.echartOption.series[serie].lineStyle.type !== undefined && Array.isArray(this.echartOption.series[serie].lineStyle.type)) {
            return this.echartOption.series[serie].lineStyle.type;
          }
        } else {
          if (this.echartOption.series[serie]['name'] === swingTransition.cycle && this.echartOption.series[serie].lineStyle.type !== undefined && Array.isArray(this.echartOption.series[serie].lineStyle.type)) {
            return this.echartOption.series[serie].lineStyle.type;
          }
        }
      }
    }
    return swingTransition.lineStyle;
  }

  public setCustomFont(font: string): void {
    this.echartOption.title['textStyle']['fontFamily'] = font;
    this.echartOption.legend['textStyle']['fontFamily'] = font;
    this.echartOption.xAxis['nameTextStyle']['fontFamily'] = font;
    this.echartOption.xAxis['axisLabel']['fontFamily'] = font;
    this.echartOption.yAxis['nameTextStyle']['fontFamily'] = font;
    this.echartOption.yAxis['axisLabel']['fontFamily'] = font;
  }

  public setFontSizes(isFullscreen: boolean, isSplitView: boolean): void {
    this.echartOption.title['textStyle']['fontSize'] = isFullscreen || isSplitView ? 14 : 13;
    this.echartOption.legend['textStyle']['fontSize'] = isFullscreen ? 14 : isSplitView ? 9 : 12;       // use for trial view and insight
    this.echartOption.legend['textStyle']['rich']['legendTitle']['fontSize'] = isFullscreen ? 14 : 12;  // used for reports
    this.echartOption.legend['textStyle']['rich']['legendItem']['fontSize'] = isFullscreen ? 14 : 12;   // used for reports
    this.echartOption.xAxis['nameTextStyle']['fontSize'] = isFullscreen || isSplitView ? 14 : 13;
    this.echartOption.yAxis['nameTextStyle']['fontSize'] = isFullscreen || isSplitView ? 14 : 13;
  }

  public getChartConfig(): ChartConfig {
    return this.chartConfig;
  }

  public setChartConfig(chartConfig: ChartConfig): void {
    this.chartConfig = chartConfig;
  }
}
