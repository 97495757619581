import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalPlaybackControlService } from 'app/core/playback-controls/global-playback-control.service';
import { Clip } from 'app/projects/report/report.types';
import { LeftRightService } from 'app/shared/left-right/left-right.service';
import { TrialChartsService } from 'app/shared/multi-chart/trial-charts.service';
import { AnalyticEvent } from 'app/shared/services/analytics/analytics.events';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { FeatureFlagsService } from 'app/shared/services/feature-flags.service';
import { DegreeConvention } from './goniometer-overlay/goniometer-overlay.component';
import { AMediaPlayerComponent } from './media-player.abstract';
import { MediaData } from './media-player.types';
import { RulerAngles } from './saga-ruler-overlay/saga-ruler-overlay.component';
import { MediaPlayerOverlayService } from './services/media-player-overlay.service';
import { MediaPlayerService } from './services/media-player.service';
import { VideoTrackService } from './video-tracks.service';
import { VideoForceOverlayService } from '../video-force-overlay/video-force-overlay.service';

@Component({
  selector: 'app-media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss'],
  providers: [ FeatureFlagsService, ]
})
export class MediaPlayerComponent extends AMediaPlayerComponent {

  @Input() clipList: Clip[] = [];
  @Input() showDropdown: boolean = false;
  @Input() tile: MediaData;
  @Output() public selectedVideoDropdownElement: EventEmitter<HTMLSelectElement> = new EventEmitter();

  constructor(
    protected playbackGlobal: GlobalPlaybackControlService,
    public leftRightService: LeftRightService,
     trackService: VideoTrackService,
    protected readonly mediaPlayerOverlayService: MediaPlayerOverlayService,
    protected readonly mediaPlayerService: MediaPlayerService,
    protected readonly featureFlagsService: FeatureFlagsService,
    protected readonly trialChartsService: TrialChartsService,
    protected readonly analyticsService: AnalyticsService,
    protected readonly videoForceOverlayService: VideoForceOverlayService,
  ) {
    super(playbackGlobal, leftRightService, trackService, mediaPlayerOverlayService, mediaPlayerService, featureFlagsService, trialChartsService, videoForceOverlayService);
  }

  public selectedGonioOption: string;

  public selectVideo(videoDropdownElement: HTMLSelectElement): void {
    this.selectedVideoDropdownElement.emit(videoDropdownElement);
  }

  public dropdownCycleVideos(): boolean {
    return this.showDropdown && this.trackService.getTracksById(this.tile.id)?.length > 1;
  }

  public canShowSplitscreenTrialDropdown(): boolean {
    return !!this.featureFlagsService.get('enableSplitscreenTrialDropdown');
  }

  public showTrialDropdown(): boolean {
    return this.canShowSplitscreenTrialDropdown() && this.showDropdown;
  }

  public keyPressing($event: number): void {
    if ($event === 32) {
      this._playbackControl.togglePlayPause();
    }
  }

  public currentSelectedGoniometerOption(event: any): void {
    this.selectedGonioOption = event;
  }

  public disableKeyboardEvent($event): void {
    $event.preventDefault();
  }

  public toggleGoniometer(toggle: boolean): void {
    if (toggle) {
      this.analyticsService.trackEvent(AnalyticEvent.ENABLE_GONIOMETER);
    }
    this.overlayEnabled = toggle;
    this.goniometerModeActive = false;
    this.rulerModeActive = false;
    if (this.canEnableGoniometer() && this.canEnableRuler()) {
      this.dropdownActive = toggle;
      this.changeGoniometerDropdown();
      // if we are toggling off, we need to make sure that both modes from the dropdown are disabled
      if (!toggle) {
        this.goniometerModeActive = false;
        this.rulerModeActive = false;
      }
    } else if (this.canEnableGoniometer() && !this.canEnableRuler()) {
      this.goniometerModeActive = toggle;
    } else if (!this.canEnableGoniometer() && this.canEnableRuler()) {
      this.rulerModeActive = toggle;
    }
    this.currentGoniometerAngle = undefined;
    this.currentRulerAngles = undefined;
  }

  public dropdownVisible(): boolean {
    return this.dropdownActive && (this.isFullscreen || this.splitView);
  }

  public changeGoniometerDropdown(): void {
    if (this.goniometerDropdownValue) {
      this.goniometerModeActive = true;
      this.rulerModeActive = false;
    } else {
      this.goniometerModeActive = false;
      this.rulerModeActive = true;
      this.currentGoniometerAngle = undefined;
      this.currentRulerAngles = undefined;
    }
  }

  public displayGoniometerSelection(angles: DegreeConvention): void {
    this.currentGoniometerAngle = angles;
  }

  public displaySagaRulerAngles(angles: RulerAngles): void {
    this.currentRulerAngles = angles;
  }

  public canEnableGoniometer(): boolean {
    return this.featureFlagsService.get('enableGoniometer') && (this.isFullscreen || this.splitView);
  }

  public canEnableAutomaticGoniometer(): boolean {
    return this.featureFlagsService.get('enableAutomaticGoniometer') && (this.isFullscreen || this.splitView);
  }

  public canEnableRuler(): boolean {
    return this.featureFlagsService.get('enableSAGARuler') && (this.isFullscreen || this.splitView);
  }
}
