import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionService } from 'app/projects/patient-view/create-session/session.service';
import { Session } from 'app/projects/patient-view/create-session/session.types';
import { DirectoryOptions } from 'app/projects/project-info/project-info-data.service';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { DirectoryUploadService, SessionUploadConfig } from './directory-upload.service';
import { GrailDirectoryService } from './grail-directory.service';
import { MoxDirectoryService } from './mox-directory.service';
import { NoraxonDirectoryService } from './noraxon-directory.service';
import { TheiaDirectoryService } from './theia-directory.service';
import { UploadOptionEnum } from './upload-options.enum';
import { Trial, ViconDirectoryService } from './vicon-directory.service';
import { SubjectContextService } from 'app/projects/subject-context/subject-context.service';

interface DirectoryProvidersConfig {
  noraxon: DirectoryProviderConfig,
  vicon: DirectoryProviderConfig,
  grail: DirectoryProviderConfig
  mox: DirectoryProviderConfig,
  theia: DirectoryProviderConfig,
}

interface DirectoryProviderConfig {
  enabled: boolean,
  label: string,
  service: DirectoryUploadService,
  options: DirectoryOptions,
}

@Component({
  selector: 'app-upload-directory',
  templateUrl: './upload-directory.component.html',
  styleUrls: ['./upload-directory.component.scss'],
  providers: [ViconDirectoryService, NoraxonDirectoryService, TheiaDirectoryService, FeatureFlagsService] // This makes sure we have a service per component.
})
export class UploadDirectoryComponent implements OnInit {
  @Input() projectId: string;
  @Input() session: Session;

  @Output() expectedUploads: EventEmitter<File[]> = new EventEmitter<File[]>();
  public conditions: { [key: string]: Trial[] } = {}; // Trials aggregated by condition
  protected configFile: File;  // the reference to the JSON file that contains the upload configuration, if present
  public sortedConditions: string[] = [];
  public directoryWasRead = false;
  public error: string;
  public uploading = false;
  protected availableDirectoryProviders: DirectoryProvidersConfig = {
    noraxon: { enabled: false, service: undefined, options: undefined, label: 'Drag and drop or click and select a Noraxon directory to read it' },
    vicon: { enabled: false, service: undefined, options: undefined, label: 'Drag and drop to upload one or more Vicon Nexus session directories, or click and select a single directory at a time' },
    grail: { enabled: false, service: undefined, options: undefined, label: 'Drag and drop or click and select a GRAIL directory to read it' },
    mox: { enabled: false, service: undefined, options: undefined, label: 'Drag and drop or click and select a MOX directory to read it' },
    theia: { enabled: false, service: undefined, options: undefined, label: 'Drag and drop or click and select a Theia directory to read it' },
  };
  public folderTypeDescription: string = '';
  private uploadingFiles: File[] = [];
  public service: DirectoryUploadService;
  public folderTypeString: string = '';
  public messages: string[] = [];
  public selectedUploadOption: UploadOptionEnum;
  /**
   * DO NOT REMOVE: necessary for the template to read the Enum values
   * The linter might say it's not used, but it is used in the template of this component
   * */
  public UploadOptionEnum = UploadOptionEnum;
  public configDataAvailable: string[] = []

  constructor(
    public readonly noraxonDirectoryService: NoraxonDirectoryService,
    public readonly viconDirectoryService: ViconDirectoryService,
    public readonly grailDirectoryService: GrailDirectoryService,
    public readonly moxDirectoryService: MoxDirectoryService,
    public readonly theiaDirectoryService: TheiaDirectoryService,
    private readonly featureFlags: FeatureFlagsService,
    private readonly sessionService: SessionService,
    private readonly subjectService: SubjectContextService,
  ) { }

  public ngOnInit(): void {
    // initialize upload options
    this.initUploadOption();
    // initialize directory providers if feature flags are enabled
    if (this.featureFlags.get('canUploadNoraxonDirectory')) {
      this.availableDirectoryProviders.noraxon.service = this.noraxonDirectoryService;
      this.availableDirectoryProviders.noraxon.options = this.featureFlags.get('canUploadNoraxonDirectory') as DirectoryOptions;
      this.enableProvider('noraxon');
    }
    if (this.featureFlags.get('canUploadGRAILDirectory')) {
      this.availableDirectoryProviders.grail.service = this.grailDirectoryService;
      this.availableDirectoryProviders.grail.options = this.featureFlags.get('canUploadGRAILDirectory') as DirectoryOptions;
      this.enableProvider('grail');
    }
    if (this.featureFlags.get('canUploadMoxDirectory')) {
      this.availableDirectoryProviders.mox.service = this.moxDirectoryService;
      this.availableDirectoryProviders.mox.options = this.featureFlags.get('canUploadMoxDirectory') as DirectoryOptions;
      this.enableProvider('mox');
    }
    if (this.featureFlags.get('canUploadViconDirectory')) {
      this.availableDirectoryProviders.vicon.service = this.viconDirectoryService;
      this.availableDirectoryProviders.vicon.options = this.featureFlags.get('canUploadViconDirectory') as DirectoryOptions;
      this.enableProvider('vicon');
    }
    if (this.featureFlags.get('canUploadTheiaDirectory')) {
      this.availableDirectoryProviders.theia.service = this.theiaDirectoryService;
      this.availableDirectoryProviders.theia.options = this.featureFlags.get('canUploadTheiaDirectory') as DirectoryOptions;
      this.enableProvider('theia');
    }
    this.setDefaultUploadOption();
  }

  /**
   * Initialize the selectedUploadOption.
   */
  private initUploadOption(): void {
    this.configDataAvailable = [];
    if (this.featureFlags.get('showUploadOptions') === true) {
      this.selectedUploadOption = UploadOptionEnum.NonVideosOnly;
    } else {
      this.selectedUploadOption = UploadOptionEnum.AllFiles;
    }
  }

  private setDefaultUploadOption(): void {
    const noraxonOptions = this.availableDirectoryProviders.noraxon.options;
    const viconOptions = this.availableDirectoryProviders.vicon.options;
    const grailOptions = this.availableDirectoryProviders.grail.options;
    const moxOptions = this.availableDirectoryProviders.mox.options;
    const theiaOptions = this.availableDirectoryProviders.theia.options;
    if (this.session?.metadata?.label) {
      if (noraxonOptions && noraxonOptions.sessionLabelFilter) {
        for (const label of noraxonOptions.sessionLabelFilter) {
          if (label.length > 1 && this.session.metadata.label.includes(label)) {
            this.enableProvider('noraxon');
          }
        }
      }
      if (grailOptions && grailOptions.sessionLabelFilter) {
        for (const label of grailOptions.sessionLabelFilter) {
          if (label.length > 1 && this.session.metadata.label.includes(label)) {
            this.enableProvider('grail');
          }
        }
      }
      if (moxOptions && moxOptions.sessionLabelFilter) {
        for (const label of moxOptions.sessionLabelFilter) {
          if (label.length > 1 && this.session.metadata.label.includes(label)) {
            this.enableProvider('mox');
          }
        }
      }
      if (viconOptions && viconOptions.sessionLabelFilter) {
        for (const label of viconOptions.sessionLabelFilter) {
          if (label.length > 1 && this.session.metadata.label.includes(label)) {
            this.enableProvider('vicon');
          }
        }
      }
      if (theiaOptions && theiaOptions.sessionLabelFilter) {
        for (const label of theiaOptions.sessionLabelFilter) {
          if (label.length > 1 && this.session.metadata.label.includes(label)) {
            this.enableProvider('theia');
          }
        }
      }
    }
  }

  public enableProvider(provider: keyof DirectoryProvidersConfig): void {
    const config = this.availableDirectoryProviders[provider];
    config.enabled = true;
    this.folderTypeDescription = config.label;
    this.service = config.service;
    this.service.setOptions(config.options);
    this.initUploadOption();
    this.service.setUploadOption(this.selectedUploadOption);
    this.reset();
  }

  public async readSelectedDirectoryOrFiles(fileList: FileList): Promise<void> {
    await this.readDirectory(Array.from(fileList));
  }

  public async readDirectory(newFiles: File[]): Promise<void> {
    let trials = [];
    this.uploadingFiles = this.uploadingFiles.concat(newFiles);
    // check if we have an upload config
    this.configFile = this.uploadingFiles.find(x => x.name.toLowerCase() === 'moveshelf_config_import.json');
    this.service.resetUploadConfig();
    this.configDataAvailable = [];
    if (this.configFile) {
      const configData_ = await this.configFile.text();
      const configData: SessionUploadConfig = JSON.parse(configData_);

      const currentSubjectMetadata = this.subjectService.getSubjectMetadata({exclude: ["interventions"]});
      if (configData?.subjectMetadata) {
        if (Object.keys(currentSubjectMetadata).length === 0) {
          this.configDataAvailable.push('Subject metadata');
        } else {
          this.configDataAvailable.push('Subject metadata (NOTE: This operation will override existing metadata)');
        }
      }

      const currentIntervention = this.subjectService.getSubjectMetadata({key: "interventions"});
      if (configData?.interventionMetadata) {
        if (
          Object.keys(currentIntervention).length === 0 || 
          (Array.isArray(currentIntervention["interventions"]) && currentIntervention["interventions"].length == 0)
        ) {
          this.configDataAvailable.push('Intervention metadata');
        } else {
          this.configDataAvailable.push('Intervention metadata (NOTE: This operation will override existing interventions)');
        }
      }

      const sessionMetadata = this.session.metadata || {};
      const currentSessionMetadata = sessionMetadata?.metadata;
      if (configData?.sessionMetadata) {
        if (currentSessionMetadata === undefined) {
          this.configDataAvailable.push('Session metadata');
        } else {
          this.configDataAvailable.push('Session metadata (will not be applied, session already has metadata)');
        }
      }

      if (configData?.conditionDefinition) {
        this.configDataAvailable.push('Condition definition');
      }
      if (configData?.trialSideSelection) {
        this.configDataAvailable.push('Trial side selection');
      }
      if (configData?.invalidTrials) {
        this.configDataAvailable.push('Trial invalid data');
      }
      if (configData?.representativeTrials) {
        this.configDataAvailable.push('Representative trial selection');
      }
      this.service.setUploadConfig(configData);
    }

    this.service.setFiles(this.uploadingFiles);
    trials = await this.service.getTrials();
    this.conditions = {};

    // Loop over trials and group them by condition to display them as aggregates
    for (const trial of trials) {
      let classification = '';
      if ((trial.plane && trial.plane.length > 0) || (trial.side && trial.side.length > 0)) {
        classification += ' (';
        let spaceStr = '';
        if (trial.plane && trial.plane.length > 0) {
          classification += trial.plane;
          spaceStr = ' ';
        }
        if (trial.side && trial.side.length > 0) {
          classification += spaceStr + trial.side;
        }
        classification += ')';
      }
      trial.name += classification;
      const condition = trial.condition;
      if (this.conditions[condition]) {
        this.conditions[condition].push(trial);
      } else {
        this.conditions[condition] = [trial];
      }
    }
    this.sortedConditions = await this.service.getSortedConditions();
    this.directoryWasRead = true;
  }

  async upload(): Promise<void> {
    let expectedUploads: File[];
    try {
      expectedUploads = await this.service.getExpectedUploads();
    } catch (folderParsingError) {
      console.error('Error while parsing uploaded folder to get expected files', folderParsingError);
      this.messages.push('Error while parsing uploaded folder to get expected files');
      return;
    }
    this.expectedUploads.emit(expectedUploads); // used for progress bar

    // Reset the DND panel
    this.reset();
    this.uploading = true;

    this.updateSubjectMetadata();

    // Save session metadata, if needed
    this.updateSessionMetadata();

    // Start the trials uploads
    try {
      await this.service.upload(this.session);
    } catch (uploadError) {
      console.error('Error while uploading one or more files:', uploadError);
      this.messages.push('Error while uploading one or more files');
    }
    this.uploading = false;
    this.reset();
  }

  updateSubjectMetadata() {
    let update = false;
    const uploadConfig = this.service.getUploadConfig();

    if (uploadConfig.subjectMetadata) {
      this.subjectService.updateSubjectMetadata(uploadConfig.subjectMetadata);
      update = true;
    }

    if (uploadConfig.interventionMetadata) {
      this.subjectService.updateSubjectMetadata({"interventions": uploadConfig.interventionMetadata});
      update = true;
    }

    if (update) {
      this.subjectService.writeCurrentMetadataInfo().subscribe( ({data}) => {
        if (data) {
          console.log('Subject metadata updated successfully');
        }
      });
    }
  }

  reset(): void {
    this.directoryWasRead = false;
    this.uploadingFiles = [];
    this.conditions = {};
  }

  private async updateSessionMetadata(): Promise<void> {
    const sortedConditions = await this.service.getSortedConditions();
    const uploadConfig = this.service.getUploadConfig();

    if ((sortedConditions).length > 0 || uploadConfig !== undefined || uploadConfig.sessionMetadata !== undefined) {
      const sessionMetadata = this.session.metadata || {};
      const curMetadata = sessionMetadata?.metadata;
      if (curMetadata == undefined && uploadConfig.sessionMetadata !== undefined) {
        sessionMetadata.metadata = uploadConfig.sessionMetadata;
        delete uploadConfig.sessionMetadata;
      }
      // store the necessary upload config. This is then used in session-view.component.ts
      // We only need the trialSideSelection as the rest of the uploadConfig is stored directly in the clip
      // custom options at clip creation time.
      sessionMetadata.uploadConfig = {
        trialSideSelection: uploadConfig?.trialSideSelection        
      };
      sessionMetadata.conditionsOrder = sortedConditions;
      try {
        await this.sessionService.update(this.session.id, this.session.name, sessionMetadata);
      } catch (metadataUpdateError) {
        console.error('Error while saving conditions order to session', metadataUpdateError);
        return;
      }
    }
  }

  public getAvailableProviders(): string[] {
    const availableProviders = [];
    for (const provider in this.availableDirectoryProviders) {
      if (this.availableDirectoryProviders[provider].enabled === true) {
        availableProviders.push(provider);
      }
    }

    return availableProviders;
  }

  protected canShowUploadOptions(): boolean {
    return this.directoryWasRead && this.featureFlags.get('showUploadOptions') === true;
  }

  public handleUploadOptionSelection(): void {
    this.service.setUploadOption(this.selectedUploadOption);
  }
}
