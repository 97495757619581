import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { UpdatePatientMutation, UpdatePatientNameMutation } from 'app/shared/mutations';
import { MetadataObject } from 'app/shared/services/metadata.service';
import SessionStorageService from 'app/shared/services/session-storage.service';
import gql from 'graphql-tag';
import { firstValueFrom } from 'rxjs';
import { LongitudinalChartService } from '../longitudinal-chart.service';

const SUBJECT_LOCK_KEY = 'subject_lock';

const PatientMetaDataByIdQuery = gql`
  query getPatientMetaData($id: ID!) {
    node(id: $id) {
      ... on Patient {
        id,
        name,
        metadata,
      }
    }
  }
`;
@Injectable({
  providedIn: 'root'
})
export class SubjectContextService {


  subjectMetadata;
  subjectId;
  interventionDateChosenMetadataKey = 'procedure-intervention-date-chosen';

  private currentMetadataTabs = {};

  constructor(
    private apollo: Apollo,
    private readonly sessionStorage: SessionStorageService,
    private readonly router: Router,
    private readonly longitudinalChartService: LongitudinalChartService,
  ) {}

  setSubjectInfo(subjectId, subjectMetadata) {
    this.subjectId = subjectId;
    this.subjectMetadata = subjectMetadata;
  }

  /**
   * Retrieves subject metadata based on the provided options.
   * @param {Object} [options] - Options to filter the metadata.
   * @param {string} [options.key] - The key of the metadata to retrieve.
   * @param {string[]} [options.exclude] - An array of keys to exclude from the metadata.
   * @returns {Object} The filtered metadata based on the provided options. 
   */
  
  getSubjectMetadata(options?: { key?: string; exclude?: string[] }): MetadataObject {
    // If no options are provided, return the full metadata
    if (!options || (!options.key && !options.exclude)) {
      return this.subjectMetadata;
    }
  
    // If a specific key is provided, return only that key
    if (options.key) {
      if (this.subjectMetadata.hasOwnProperty(options.key)) {
        return { [options.key]: this.subjectMetadata[options.key] };
      } else {
        // Key not found, return an empty object or handle as needed
        return {};
      }
    }
  
    // If an exclude array is provided, return metadata without those keys
    if (options.exclude) {
      // Create a shallow copy of the metadata
      const filteredMetadata = { ...this.subjectMetadata };
      // Remove each key specified in the exclude array
      for (const key of options.exclude) {
        delete filteredMetadata[key];
      }
      return filteredMetadata;
    }
  
    // Default case: return full metadata
    return this.subjectMetadata;
  }
  

  updateSubjectMetadata(newInfo: object, clearPrevious = false) {
    if (clearPrevious) {
      this.subjectMetadata = newInfo;
    } else {
      this.subjectMetadata = { ...this.subjectMetadata, ...newInfo };
    }
  }

  clearProgressionMeasures(metadataNames: string[]): void {
    for (const metadataName of metadataNames) {
      this.subjectMetadata[metadataName] = undefined;
    }
  }

  clearGaitspeedMeasures() {
    this.subjectMetadata['gaitspeed'] = undefined;
  }

  gaitspeedMeasuresAvailable() {
    if (!this.subjectMetadata)
      return false;
    return this.subjectMetadata['gaitspeed'] !== undefined;
  }

  addInterventionDateChosen(): boolean {
    // only store if we already have read the metadata object
    const validDate = this.longitudinalChartService.interventionDate instanceof Date && !isNaN(this.longitudinalChartService.interventionDate as any);
    if (this.subjectMetadata !== undefined && Object.keys(this.subjectMetadata).length > 0 && validDate) {
      this.subjectMetadata[this.interventionDateChosenMetadataKey] = this.longitudinalChartService.interventionDate.toISOString().split('T')[0];
      return true;
    }
    return false;
  }

  /**
   *  Create the measurement to add to patient's metadata
   */
  addMeasure(name: string, value, date, version: string = ""): boolean {
    // Create a date without time, this will be used to timestamp the metadata.
    const formattedDate = (new Date(date)).toISOString().split('T')[0];
    let hasOlderVersions = false;
    if (!isNaN(value)) {
      const measure = { "value": value, "date": formattedDate, "version": version };


      if (this.subjectMetadata[name]) {
        // only push the value the version is newer than existing versions
        let highestVersion = 0;
        for (const item of this.subjectMetadata[name]) {
          if (item.version && item.version.length > 0 && !isNaN(parseInt(item.version))) {
            highestVersion = Math.max(highestVersion, parseInt(item.version));
          }
        }
        if (highestVersion === 0) {
          // no version stored yet, so we include current
          if (measure.version.length !== 0) {
            hasOlderVersions = true;
            this.subjectMetadata[name] = [ measure ];
          } else {
            this.subjectMetadata[name].push(measure);
          }
        } else {
          // only keep items with the same version
          const newArray = [];
          for (const item of this.subjectMetadata[name]) {
            if (item.version && item.version.length > 0 && item.version === measure.version) {
              newArray.push(item);
            } else {
              hasOlderVersions = true;
            }
          }
          // now add latest measure
          newArray.push(measure);
          this.subjectMetadata[name] = newArray;
        }
      } else {
        this.subjectMetadata[name] = [ measure ];
      }
    }
    return hasOlderVersions;
  }

  updateSubjectName(newSubjectName: string) {
    return this.apollo.mutate({
      mutation: UpdatePatientNameMutation,
      variables: {
        patientId: this.subjectId,
        name: newSubjectName
      }
    });
  }

  writeCurrentMetadataInfo() {
    const infoToSave = this.subjectMetadata;
    return this.apollo.mutate({
      mutation: UpdatePatientMutation,
      variables: {
        patientId: this.subjectId,
        metadata: JSON.stringify(infoToSave)
        //metadata: JSON.stringify(this.examplePatientInfoJson)
      }
    });
  }

  async getSubjectWeight(patientId: string): Promise<number> {
    const subjectData = await firstValueFrom(this.apollo.query<any>({
      query: PatientMetaDataByIdQuery,
      variables: {
        id: patientId
      },
    }));

    let subjectWeight: number;
    const metadata = JSON.parse(subjectData.data.node.metadata);
    if (metadata && metadata['weight'] && metadata['weight'].length > 0) {
      const bodyWeights = metadata['weight'];
      bodyWeights.sort((a,b)=>(new Date(a.date)).getTime()-(new Date(b.date)).getTime());
      subjectWeight = parseInt(bodyWeights[bodyWeights.length-1].value) * 9.81;
    }

    return subjectWeight;
  }

  /**
   * @returns the subject weight found by date, id no match it's found, it returns -1
   */
  async getSubjectWeightByDate(patientId: string, date: string): Promise<number> {
    const subjectData = await firstValueFrom(this.apollo.query<any>({
      query: PatientMetaDataByIdQuery,
      variables: {
        id: patientId
      },
    }));

    let subjectWeight: number = -1;
    const metadata = JSON.parse(subjectData.data.node.metadata);
    if (metadata && metadata['weight'] && metadata['weight'].length > 0) {
      const bodyWeights = metadata['weight'];
      if (bodyWeights.find(x => this.matchDateParts(x.date, date))) {
        subjectWeight = parseInt(bodyWeights[bodyWeights.length-1].value) * 9.81;
      }
    }
    return subjectWeight;
  }

  private matchDateParts(date1: string, date2: string): boolean {
    const dateParts1 = date1.split('-');
    const dateParts2 = date2.split('-');
    if (dateParts1.length < 3 || dateParts2.length < 3) {
      return false;
    }
    return Number(dateParts1[0]) === Number(dateParts2[0]) && Number(dateParts1[1]) === Number(dateParts2[1]) && Number(dateParts1[2]) === Number(dateParts2[2]);
  }

  /**
   * Return true iif the provided route is referring a patient by EHR Id
   */
  public isRouteUsingEhrId(route: ActivatedRoute): boolean {
    return route.snapshot?.queryParamMap?.get('ehrId') !== null;
  }

  /**
   * Navigate from a subject to another. Place a lock if needed (@see #1404)
   * @param route The route object to use for navigation, should be a subject page route
   * @param subjectId The id of the target subject
   * @param placeSubjectLock True iif it is needed to place a subject lock on the target
   */
  public navigateToSubjectId(route: ActivatedRoute, subjectId: string, placeSubjectLock = false): void {
    const projectId = route.snapshot.paramMap.get('projectId');
    this.router.navigate(
      ['project', projectId, 'subject', subjectId],
      {state: {'subjetId': subjectId}}
    ).then(() => {
      if (placeSubjectLock) {
        this.setSubjectLock(subjectId);
      }
    });
  }

  public setSubjectLock(subjectId: string): void {
    this.sessionStorage.setItem(SUBJECT_LOCK_KEY, subjectId);
  }

  public getSubjectLock(): string {
    return this.sessionStorage.getItem(SUBJECT_LOCK_KEY);
  }

  public removeSubjectLock(): void {
    this.sessionStorage.removeItem(SUBJECT_LOCK_KEY);
  }

  public clearMetadataTabs(): void {
    this.currentMetadataTabs = {};
  }

  public updateMetadataTabs(dataToSave: MetadataObject): void {
    for (const key in dataToSave) {
      this.currentMetadataTabs[key] = dataToSave[key];
    }
  }

  public addMetadataTabsToSave(): void {
    for (const key in this.currentMetadataTabs) {
      this.subjectMetadata[key] = this.currentMetadataTabs[key];
    }
  }
}
