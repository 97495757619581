import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlaybackMode } from '../playback-mode.enum';
import { TimeSelection } from '../time-selection';


@Injectable()
export class PlaybackControlService {

  private _clipDuration: number;
  public set clipDuration(duration: number) {
    this.setClipDuration(duration);
  }
  public get clipDuration(): number {
    return this._clipDuration;
  }
  private _videoDuration: number;
  public set videoDuration(duration: number) {
    this.setVideoDuration(duration);
  }
  public get videoDuration(): number {
    return this._videoDuration;
  }
  _timebarDuration: number;
  public set timebarDuration(duration: number) {
    this.setTimebarDuration(duration);
  }
  public get timebarDuration(): number {
    return this._timebarDuration;
  }
  playbackTime = new BehaviorSubject<number>(0);
  mode = new BehaviorSubject<PlaybackMode>(PlaybackMode.Paused);
  selection = new BehaviorSubject<TimeSelection | null>(null);
  events = new BehaviorSubject<any[]>([]);

  private prevMode: PlaybackMode;
  protected playing = false;
  private playbackSpeed: number = 1;
  private timeJumpSeconds: number = 0.020; //20 ms
  private minFrameRate: number = 0;

  constructor() {
    this.initPlaybackMonitoring();
  }

  public timeDeltaMove(timeDelta: number) {
    if (this.mode.value !== PlaybackMode.Seeking) {
      let newTime = this.playbackTime.value + timeDelta;
      const duration = this.selection.value ? (this.selection.value.end - this.selection.value.start) : this.clipDuration;
      const start = this.selection.value ? this.selection.value.start : 0;
      const end = this.selection.value ? this.selection.value.end : this.clipDuration;

      // For videos, the last frame is 1 time frame before duration (We start at 0), so if we are jumping to duration, move back to start.
      while (newTime > end - 0.1*timeDelta) {
        newTime = newTime - duration;
      }

      while (newTime < start) {
        newTime = newTime + duration;
      }

      if (this.timeJumpSeconds > 0 && this.hasVideo()) {
        // Only allow for increments of timeDelta if we have a video
        newTime = Math.round(newTime / this.timeJumpSeconds) * this.timeJumpSeconds;
      }

      this.startSeeking();
      this.playbackTime.next(newTime);
      this.stopSeeking();

    }
  }

  public hasVideo(): boolean {
    return this.minFrameRate > 0;
  }

  public setTimeJumpSeconds(timeJump: number): void {
    this.timeJumpSeconds = timeJump;
  }

  public getTimeJumpSeconds(): number {
    return this.timeJumpSeconds;
  }

  public setMinFrameRate(frameRate: number): void {
    this.minFrameRate = frameRate;
  }

  public getMinFrameRate(): number {
    return this.minFrameRate;
  }

  public setPlaybackSpeed(playbackSpeed): void {
    this.playbackSpeed = playbackSpeed;
  }

  public getPlaybackSpeed(): number {
    return this.playbackSpeed;
  }

  public incrementTime(timeDelta: number) {
    console.assert(timeDelta >= 0);

    if (this.mode.value !== PlaybackMode.Seeking) {
      let newTime = this.playbackTime.value + timeDelta * this.playbackSpeed; // Controls the speed of the timebar

      const selection = this.selection.value;
      let updateTime = true;
      if (selection) {
        if (newTime < selection.start || newTime > selection.end) {
          newTime = selection.start;
        }
      } else {
        while (newTime > this.clipDuration) {
          newTime = newTime - this.clipDuration;
          const currentlyPlaying = this.mode.value === PlaybackMode.Playing;
          updateTime = false;   // we already update the time in the jump call.
          this.jumpToTimeBasedOnTimeJump(newTime);
          if (currentlyPlaying && this.mode.value !== PlaybackMode.Playing) {
            this.togglePlayPause();
          }
        }
      }

      if (updateTime) {
        this.playbackTime.next(newTime);
      }
    }
  }

  public play() {
    this.playing = true;
    this.mode.next(PlaybackMode.Playing);
  }

  public pause() {
    this.playing = false;
    this.mode.next(PlaybackMode.Paused);
  }

  public togglePlayPause() {
    if (this.mode.value === PlaybackMode.Paused) {
      this.play();
    } else if (this.mode.value === PlaybackMode.Playing) {
      this.pause();
      this.jumpToTimeBasedOnTimeJump(this.playbackTime.value);
    }
  }

  public jumpToTime(newTime: number) {
    this.startSeeking();
    this.playbackTime.next(newTime);
    this.stopSeeking();
  }

  public jumpToTimeBasedOnTimeJump(newTime: number): number {
    newTime = this.timeJumpSeconds > 0 ? Math.round(newTime/this.timeJumpSeconds) * this.timeJumpSeconds : newTime;
    this.jumpToTime(newTime);
    return newTime
  }

  public startSeeking() {
    this.pause();
    this.prevMode = this.mode.value;
    this.mode.next(PlaybackMode.Seeking);
  }

  public stopSeeking(mode?: PlaybackMode) {
    this.mode.next(mode ? mode : this.prevMode);
  }

  /**
   * Asks the service for a new clip duration to set. If another is already present, only update
   * it if requesting a longer one.
   * @see #1402 After unifying timebars, always keep the longest duration
   * @param duration The new desired duration
   */
  public setClipDuration(duration: number): void {
    this._clipDuration = Math.max(this._clipDuration, duration) || duration;
  }

  /**
   * Sets a duration only for the video part of the timebar, this can be used to understand if we're in the video playing part of the timebar.
   * @param duration The new desired duration
   */
  public setVideoDuration(duration: number): void {
    this._videoDuration = Math.max(this._videoDuration, duration) || duration;
  }

  /**
   * Asks the service for a new timebar duration to set. If another is already present, only update
   * it if requesting a longer one.
   * @see #1402 After unifying timebars, always keep the longest duration
   * @param duration The new desired duration
   */
  public setTimebarDuration(duration: number): void {
    this._timebarDuration = Math.max(this._timebarDuration, duration) || duration;
  }

  public resetDuration(): void {
    this._timebarDuration = 0;
    this._clipDuration = 0;
  }

  /**
   * Creates the main playback subscription loop.
   * This monitors the playback status and increases the timebar by the desired amount if needed.
   */
  private initPlaybackMonitoring() {
    const refreshInterval = 0.0333; // s, tentative 30 fps framerate
    let lastTime = 0;
    setInterval(() => {
      const currentTime = performance.now();
      const delta = (currentTime - lastTime) / 1000; // calculate actual deltaT from last call
      lastTime = currentTime;
      if (this.playing) {
        this.incrementTime(delta);
      } else {
        // This is needed to keep any 3D clip "alive" at start
        this.incrementTime(0);
      }
    }, refreshInterval * 1000); // ms

  }

  public isPlaying(): boolean {
    return this.mode.value === PlaybackMode.Playing;
  }
}
