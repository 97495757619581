import gql from 'graphql-tag';

export const CreateSessionMutation = gql`
      mutation createSessionMutation($projectId: String!, $projectPath: String!, $patientId: ID!, $sessionDate: String) {
        createSession(projectId: $projectId, projectPath: $projectPath, patientId: $patientId, sessionDate: $sessionDate) {
            session {
                id
                projectPath
                project {
                    id
                    name
                    sessions {
                      id
                      projectPath
                    }
                }
            }
        }
      }
    `;

export const UpdateSessionMutation = gql`
  mutation updateSessionMutation(
    $sessionId: ID!,
    $sessionName: String!,
    $sessionMetadata: JSONString,
    $sessionDate: String,
  ) {
    updateSession(
      sessionId: $sessionId,
      sessionName: $sessionName,
      sessionMetadata: $sessionMetadata,
      sessionDate: $sessionDate,
    ) {
      updated
    }
  }`;

export const PublishSessionMutation = gql`
  mutation publishSessionMutation($sessionId: ID!) {
    publishSession(sessionId: $sessionId) {
      status
    }
  }`;

export const UpdatePatientMutation = gql`
    mutation updatePatientMutation($patientId: ID!, $metadata: JSONString) {
      updatePatient(patientId: $patientId, metadata: $metadata) {
          updated
      }
    }
  `;

export const UpdatePatientNameMutation = gql`
  mutation UpdatePatientNameMutation($patientId: ID!, $name: String!) {
    updatePatientName(patientId: $patientId, name: $name) {
        updated
    }
  }
`;

export const CreatePatientMutation = gql`
      mutation createPatientMutation($projectId: String!, $name: String!, $metadata: JSONString) {
        createPatient(projectId: $projectId, name: $name, metadata: $metadata) {
            patient {
                id
                name
                project {
                    id
                    name
                    patients {
                        id
                        name
                    }
                }
            }
        }
      }
    `;

export const DeleteSessionMutation = gql`
    mutation deleteSessionById($sessionId: ID!) {
      deleteSession(sessionId: $sessionId) {
        deleted
      }
    }`;

export const DeletePatientMutation = gql`
    mutation deletePatientById($patientId: ID!) {
      deletePatient(patientId: $patientId) {
        deleted
      }
    }`;

export const UpsertSsoConfig = gql`
    mutation upsertSsoConfig($organizationId: ID!, $clientId: String!, $clientSecret: String, $tenantId: String!, $redirectUri: String!, $deleted: Boolean) {
      upsertOrganizationSso(organizationId: $organizationId, clientId: $clientId, clientSecret: $clientSecret, tenantId: $tenantId, redirectUri: $redirectUri, deleted: $deleted) {
        updated
      }
    }
`;

export const createReportChartsJsonsMutation = gql`
  mutation generateReportChartsJsonsMutation($sessionId: ID, $chartsJson: JSONString, $reportId: ID) {
    generateReportChartsJsons(sessionId: $sessionId, chartsJson: $chartsJson, reportId: $reportId) {
        blobKey, chartUrl
      }
    }
`;

export const createGaitReportMutation = gql`
  mutation generateGaitReportMutation($sessionId: ID) {
    generateGaitReport(sessionId: $sessionId) {
        created
      }
    }
`;

export const createWordReportMutation = gql`
  mutation generateWordReportMutation($exporterType: String!, $sessionId: ID, $patientId: ID, $base64ImageDict: JSONString) {
      generateWordReport(exporterType: $exporterType, sessionId: $sessionId, patientId: $patientId, base64ImageDict: $base64ImageDict) {
        signedUrl
        signedUrlPdf
      }
    }
`;
