<!-- <app&#45;sharing&#45;clip [allowCodeSnippet]="true" [urlToShare]="urlToShare" #shareButton></app&#45;sharing&#45;clip> -->
<div class="clip d-flex align-items-center" #container>
  <div id="hover-container" class="wrapper" [ngClass]="{'always-visible-icons': changeSize || (isInsight && fullScreenEnabled)}">
    <div *ngIf="isActionAvailable() && !hideControls" [@controlState]="controlState" class="top-controls noselect">
      <i *ngIf="hasValidCameraCalibrationData()" class="fa fa-fast-forward mr-2" (click)="selectNextVideo()" [ngClass]="{'reposition-icon': moveFollowCam === true}"
        tooltip="Cycle video overlay" placement="auto" title="Cycle video overlay"></i>
      <i [class]="'fa fa-crosshairs ' + (enableFollowCam ? 'enabled' : 'disabled')" (click)="toggleFollowCam()"
        [ngClass]="{'reposition-icon': moveFollowCam === true}" tooltip="Follow cam" placement="auto"></i>
      <!-- <i *ngIf="vrDisplay" class="fa fa-cog ml-3" (click)="enterVR()" tooltip="VR" placement="auto"></i> -->
      <span *ngIf="!this.globalTimebar"
        [class]="'clickable-success ml-3 mr-0' + (getTimeScalingState() === 99 ? ' enabled' : ' disabled')"
        style="font-size: 1.1em" (click)="selectNextTimeScalingState()">{{timeScaling === 1 ? 1 :
        timeScaling.toFixed(2)}}x</span>
      <!-- <svg-icon *ngIf="transparencyAvailable" src="/assets/mesh3.svg" class="svg-clickable-success ml-3 disabled" tooltip="Technical mesh" placement="auto" style="width: 18px;" (click)="toggleTransparency()"></svg-icon>
      <svg-icon *ngIf="vrDisplay" src="/assets/vr_icon2.svg" class="svg-clickable-success ml-3 disabled" tooltip="VR" placement="auto" style="width: 23px;" (click)="enterVR()"></svg-icon> -->
      <span *ngIf="takesNumber > 1" class="ml-3 clickable-success highlight-success" style="font-size: 1.1em"
        (click)="selectNextTake()">{{takeIndex+1}}/{{takesNumber}}</span>
    </div>
    <canvas class="scene" (onResize)="onResize($event)" (tap)="showControls()" (mouseover)="showControls()"
      (mousemove)="showControls()" #scene> </canvas>
    <i *ngIf="showFullscreenIcon"
      [class]="'clickable-success fa fa-' + (this.fullScreenEnabled ? 'compress' :  'expand')"
      style="position: absolute; top: 10px; right: 10px" aria-hidden="true" (click)="toggleFullscreen()"></i>
    <app-media-player [playbackControl]="playbackControl" [layout]="undefined" [playbackSpeed]="playbackSpeed" [fullscreen]="fullScreenEnabled || changeSize"
      style="width: 100%; height: 100%; display:none" #media [clickers]="false" [isMediaPlayerIn3D]="true" [isInsight]="isInsight"></app-media-player>
    <!-- <app-clip-controls *ngIf="!this.globalTimebar && isActionAvailable() && !hideControls && duration > 0" [@controlState]="controlState" class="controls-overlay" [fullControls]="true" [clipId]="clipId" [includeEditLink]="includeEditLink" [includeShareButton]="includeShareButton" [allowSelectionEditing]="allowSelectionEditing" (share)="share.emit()" (fullscreen)="fullscreen($event)" (mouseenter)="cancelHideControls()" (mouseleave)="showControls()"></app-clip-controls> -->
  </div>
</div>
